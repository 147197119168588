import { NexcaliberMembers } from "../@types/nexcaliberMembers.types";

export const NEXCALIBER_MEMBER: NexcaliberMembers[] = [
	{
		member_name: "Cloy David Feachen",
		member_designation: "President",
		member_memberid: "201",
		member_email: "dfea@nexcaliber.com"
	},
	{
		member_name: "Shari Gallagher",
		member_designation: "Chief Operating Officer",
		member_memberid: "101",
		member_email: "sgal@nexcaliber.com"
	},
	{
		member_name: "Carole Guzman- Small",
		member_designation: "Bookkeeper",
		member_memberid: "205",
		member_email: "cguz@nexcaliber.com"
	},
	{
		member_name: "Mary Tyer",
		member_designation: "Head of Customer Relationship -Benefit",
		member_memberid: "122",
		member_email: "mtye@nexcaliber.com"
	},
	{
		member_name: "Marina Kononovitch",
		member_designation: "Head of Customer Relationship -Enrollment",
		member_memberid: "122",
		member_email: "mkon@nexcaliber.com"
	}
];
