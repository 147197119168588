import React from "react";

import "./eligibilityCheckDetailCard.css";
import initCapitalize from "../../../../utils/commonFunctions/initCapitalize";
import { SSNMasking } from "../../../../utils/commonFunctions/SSNMasking";
import {
	EligibilityCheckCardDetails,
	EligibilityCheckEligibleMembers
} from "../../../../@types/eligibilityCheck.types";
import { groupNameMapper } from "../../../../utils/commonFunctions/groupNameMapper";
import { ZIPFormat } from "../../../../utils/commonFunctions/ZIPFormat";
import { USAStateMapper } from "../../../../utils/commonFunctions/USAStateMapper";
import { maskSSN } from "../../../../utils/commonFunctions/maskSSN";

const EligibilityCheckDetailCard: React.FC<EligibilityCheckCardDetails> = ({
	cardDetailProp,
	coveredEmployee
}): JSX.Element => {
	const {
		group_number,
		member_id,
		role,
		first_name,
		middle_name,
		last_name,
		date_of_birth,
		SSN,
		relationship,
		effective_date,
		benefit_end_date,
		address_line_1,
		address_line_2,
		city,
		state,
		ZIP,
		medical,
		drug,
		dental,
		vision,
		miscellaneous
	} = cardDetailProp;

	console.log("======coveredEmployee====", coveredEmployee);
	return (
		<div className="eligibility-check-details-main" id="eligibility-check-details-main">
			<span className="card-main-title">Eligibility Details</span>
			<div className="card-sub-title">Employer Details:</div>
			<div className="card-section-container">
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">Group Number:</div>
						<div className="value-field">{group_number}</div>
					</div>
					<div className="fields-container">
						<div className="title-field">Group Name:</div>
						<div className="value-field">
							{group_number === 219 ? "Entira Clinic" : groupNameMapper(group_number)}
						</div>
					</div>
				</div>
			</div>
			<div className="card-sub-title">Member Details:</div>
			<div className="card-section-container">
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">Member Name:</div>
						<div className="value-field">{first_name + " " + middle_name + " " + last_name}</div>
					</div>
					<div className="fields-container">
						<div className="title-field">Role:</div>
						<div className="value-field">{role}</div>
					</div>
				</div>
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">Member ID:</div>
						<div className="value-field">{member_id ? member_id : "N/A"}</div>
					</div>
					<div className="fields-container">
						<div className="title-field">Relationship:</div>
						<div className="value-field">{relationship}</div>
					</div>
				</div>
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">Date of Birth:</div>
						<div className="value-field">{date_of_birth}</div>
					</div>
					<div className="fields-container">
						<div className="title-field">SSN:</div>
						<div className="value-field">{"XXX-XX-" + maskSSN(String(SSN))}</div>
					</div>
				</div>
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">Insured Employee:</div>
						<div className="value-field">
							{coveredEmployee
								? coveredEmployee.first_name +
								  " " +
								  coveredEmployee.middle_name +
								  " " +
								  coveredEmployee.last_name
								: "N/A"}
						</div>
					</div>
					<div className="fields-container">
						<div className="title-field">Insured Employee SSN:</div>
						<div className="value-field">
							{coveredEmployee ? "XXX-XX-" + maskSSN(String(coveredEmployee?.SSN)) : "N/A"}
						</div>
					</div>
				</div>
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">Address:</div>
						<div className="value-field">
							{(address_line_1 as unknown as string) +
								(address_line_2 ? (address_line_2 as unknown as string) : "")}
						</div>
					</div>
					<div className="fields-container">
						<div className="title-field">City:</div>
						<div className="value-field">{city}</div>
					</div>
				</div>
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">State:</div>
						<div className="value-field">{USAStateMapper(state as unknown as string)}</div>
					</div>
					<div className="fields-container">
						<div className="title-field">ZIP:</div>
						<div className="value-field">{ZIPFormat(String(ZIP))}</div>
					</div>
				</div>
			</div>
			<div className="card-sub-title">Benefit Details:</div>
			<div className="card-section-container">
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">Effective Date:</div>
						<div className="value-field">{effective_date}</div>
					</div>
					<div className="fields-container">
						<div className="title-field">Benefit To:</div>
						<div className="value-field">{benefit_end_date}</div>
					</div>
				</div>
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">{group_number === 219 ? "Clinic Access" : "Medical"}:</div>
						<div className="value-field">{medical}</div>
					</div>
					<div className="fields-container">
						<div className="title-field">Drug:</div>
						<div className="value-field">{drug}</div>
					</div>
				</div>
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">Dental:</div>
						<div className="value-field">{dental}</div>
					</div>
					<div className="fields-container">
						<div className="title-field">Vision:</div>
						<div className="value-field">{vision}</div>
					</div>
				</div>
				<div className="card-section">
					<div className="fields-container">
						<div className="title-field">Miscellaneous:</div>
						<div className="value-field">{miscellaneous}</div>
					</div>
					<div className="fields-container"></div>
				</div>
			</div>
		</div>
	);
};

export default EligibilityCheckDetailCard;
