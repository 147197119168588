import React from "react";
import { Services } from "../index";
import { FAQ, Article, Hero, PlanDesignGroups } from "../../shared";
import "./home.css";
import CallToAction from "./callToAction/CallToAction";
import Advantage from "./advantage/Advantage";
import NexcaliberAdvantage from "./nexcaliberAdvantage/NexcaliberAdvanatge";

//Home Page
const Home: React.FC = () => {
	return (
		<div className="main-home-container">
			<Hero />
			<CallToAction />
			{/* <Advantage /> */}
			<Article />
			<NexcaliberAdvantage />
			<Services />
			<div className="why-nexcal">
				<div className="plan-design">
					<PlanDesignGroups />
				</div>
				<div className="faqq">
					<FAQ />
				</div>
			</div>
		</div>
	);
};

export default Home;
