import React from "react";
import { IRemarks } from "../../../../../../@types/components/remarks.types";

const RemarksCardv2 = ({ remark, index, remark_id }: { remark: IRemarks; index: number; remark_id: string }) => {
	console.log("Checking the id-->", remark_id);

	return (
		<div style={{ margin: "0 0 0 40px" }}>
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}></div>
			<div className="inline-flex" style={{ display: "flex", justifyContent: "flex-start", marginTop: "10px" }}>
				{remark.EXPLANATION_CODE_1 !== 0 && (
					<div className="inline-flex" style={{ display: "flex", marginRight: "10px" }}>
						<p style={{ fontWeight: "bold" }}>{remark.EXPLANATION_CODE_1}</p>
					</div>
				)}
				{remark.EXPLANATION_CODE_2 !== 0 && (
					<div className="inline-flex" style={{ display: "flex", marginRight: "10px" }}>
						<p style={{ fontWeight: "bold" }}>{remark.EXPLANATION_CODE_2}</p>
					</div>
				)}
				{remark.EXPLANATION_CODE_3 !== 0 && (
					<div className="inline-flex" style={{ display: "flex", marginRight: "10px" }}>
						<p style={{ fontWeight: "bold" }}>{remark.EXPLANATION_CODE_3}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default RemarksCardv2;
