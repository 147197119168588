import React from "react";
import { PLANDESIGNGROUPS } from "../../../../constants/planDesignGroupsDetails";

import "./planDesignGroups.css";

const PlanDesignGroups: React.FC = () => {
	return (
		<div className="plan-design-groups">
			<div className="plan-design-groups-content-part">
				<h2 className="roadmap-title">Nexcaliber</h2>

				<div className="roadmap-design">
					<div className="roadmap-content-container">
						{PLANDESIGNGROUPS.map((group, index) => {
							return (
								<div className="roadmap-content-items" key={index}>
									<div className="roadmap-line">
										<div className="roadmap-semi-circle-top"></div>
										<div className="roadmap-dots">
											<span className="roadmap-dot"></span>
											<span className="roadmap-dot"></span>
											<span className="roadmap-dot"></span>
										</div>
										<div className="roadmap-semi-circle-bottom"></div>
									</div>
									<div className="roadmap-outer-circle">
										<div className="roadmap-triangle"></div>

										<div className="roadmap-inner-circle">
											<img className="roadmap-content-icon" src={group.icons} />
										</div>
										<div className="roadmap-triangle-content">
											<div className="roadmap-content-label">{group.label}</div>
											<p className="roadmap-content-description">{group.description}</p>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PlanDesignGroups;
