const message = {
	post: {
		succ: "Data added successfully",
		fail: "Failed to add data",
		sameEntry: "Same entry not allowed"
	},
	get: {
		succ: "Data fetched successfully",
		fail: "Failed to fetch data",
		empty: "Database empty",
		enough: "Not Enough Data to Fetch"
	},
	put: {
		succ: "Data edited successfully",
		fail: "Failed to edit data"
	},
	patch: {
		succ: "Data edited successfully",
		fail: "Failed to edit data"
	},
	delete: {
		succ: "Data deleted successfully",
		fail: "Failed to delete data"
	},
	error: "Error",
	none: "No such data"
};

export const MESSAGE = message;
