import { ContactConstant } from "../@types/contact.types";
import { FooterAddress, FooterEmails, FooterLinks } from "../@types/footerLinks.types";
import { CONTACTS } from "./contacts";
import { NEXCALIBER_DETAILS } from "./nexcaliberDetails";

import { TfiLocationPin } from "react-icons/tfi";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";

export const FOOTER_CONTACTS: ContactConstant[] = CONTACTS;
export const FOOTER_LINKS: FooterLinks[] = [
	{
		label: "Solutions",
		redirection_link: "/"
	},
	{
		label: "Nexcaliber Advantage",
		redirection_link: "/"
	},
	{
		label: "Eligibility Check",
		redirection_link: "/eligibility-check"
	},
	{
		label: "Search Provider",
		redirection_link: "/"
	},
	{
		label: "Contact Us",
		redirection_link: "/contact"
	},
	{
		label: "Member Login",
		redirection_link: "https://admin.enrollment.nexcaliber.com/login"
	}
];

export const FOOTER_EMAILS: FooterEmails[] = [
	// {
	// 	label: "Address",
	// 	value: NEXCALIBER_DETAILS.NEXCALIBER_ADDRESS
	// 	// icon: () => <TfiLocationPin className="icon-location" />
	// },
	{
		label: "Info",
		value: NEXCALIBER_DETAILS.NEXCALIBER_MAIL
		// icon: () => <AiOutlineMail className="icon-mail" />
	},
	{
		label: "IDR",
		value: NEXCALIBER_DETAILS.NEXCALIBER_MAIL_TWO
		// icon: () => <AiOutlineMail className="icon-mail" />
	}

	// {
	// 	label: "Helpline",
	// 	value: NEXCALIBER_DETAILS.NEXCALIBER_CONTACT
	// 	// icon: () => <FiPhoneCall className="icon-phone" />
	// }
];

export const FOOTER_ADDRESS: FooterAddress[] = [
	{
		value: NEXCALIBER_DETAILS.NEXCALIBER_ADDRESS
	}
];
