import React from "react";
import { EOBEligibleMembers, ExplanationOfBenefitsGridPrintProp } from "../../../../../../@types/components/EOB.types";
import { mongoDBDateParserToUSAFormat_v2 } from "../../../../../../utils/commonFunctions/dates";
import { ITotalData } from "../../../../../../@types/totalData.types";

const formatCharge = (chargeStr: string): string => {
	const charge = chargeStr.replace("$", "");
	const chargeFloat = parseFloat(charge);
	const chargeParts = charge.split(".");
	if (chargeParts.length === 1) {
		return `$ ${chargeFloat.toFixed(2)}`;
	} else if (chargeParts[1].length === 1) {
		return `$ ${chargeFloat.toFixed(2)}`;
	} else {
		return `$ ${chargeFloat.toFixed(2)}`;
	}
};

const ExplanationOfBenefitsGridPrintv2: React.FC<ExplanationOfBenefitsGridPrintProp> = ({
	eligibleMembers,
	claimTotal
}): JSX.Element => {
	const headers = [
		"TYPE OF SERVICE",
		"SERVICE START",
		"SERVICE END",
		"TOTAL CHARGES",
		"NOT COV/PENALTY",
		"DISCOUNT",
		"ELIGIBLE EXPENSE",
		"REMARK CODE",
		"CO-PAY",
		"DEDUCTIBLE AMOUNT",
		"PAID AT PRECENTAGE",
		"BENEFITS PAID"
	];

	const totalData =
		claimTotal && claimTotal.length > 0
			? [
					{
						CODE: "Total",
						INCFRMDT: "",
						INCTODT: "",
						CHGAMT: formatCharge(`${claimTotal[0]["TOTAL_CHARGE"]}`),
						NETEERESP: formatCharge(`$ ${claimTotal[0]["TOTAL_NOTCOVERED"]}`),
						PRVRESP: formatCharge(`$ ${claimTotal[0]["TOTAL_DISCOUNT"]}`),
						ADJCVRD: formatCharge(`$ ${claimTotal[0]["TOTAL_ELIGIBLEEXPENSES"]}`),
						REMARK_CODE: "",
						COPAMT: formatCharge(`$ ${claimTotal[0]["TOTAL_COPAY"]}`),
						DEDAMT: formatCharge(`$ ${claimTotal[0]["TOTAL_DEDUCTIBLE"]}`),
						PERC: "",
						PAYMENT: formatCharge(`$ ${claimTotal[0]["PAYMENT"]}`)
					}
			  ]
			: [];

	const headersWithValue = Object.keys(eligibleMembers[0]);

	console.log("==========headersWithValue==========", headersWithValue);

	const numericColumns = ["total_charges", "discount", "deductable_amount", "paid_at_percentage", "benefit_paid"];

	const addCommaSuffixToTableValue = (value: string): string => {
		if (!value.includes(".")) {
			return `${value}.00`;
		} else {
			const parts = value.split(".");
			return parts[1].length === 1 ? `${value}.0` : value;
		}
	};

	// const calculateTotal = (column: keyof EOBEligibleMembers) => {
	// 	return eligibleMembers.reduce((total, member) => {
	// 		const columnValue = member[column];
	// 		return typeof columnValue === "number" ? total + columnValue : total;
	// 	}, 0);
	// };

	// console.log("=====>headers with value", headersWithValue);

	// const totalRow = (
	// 	<tr>
	// 		{headers.map((header, index) => (
	// 			<td key={index} style={{ border: "1px solid black", padding: "8px" }}>
	// 				{numericColumns.includes(header) ? calculateTotal(header as keyof EOBEligibleMembers) : ""}
	// 			</td>
	// 		))}
	// 	</tr>
	// );

	// console.log("========eligibleMembers====", eligibleMembers);

	return (
		<table style={{ borderCollapse: "collapse", width: "100%", marginTop: "10px" }}>
			<thead style={{ backgroundColor: "#e2ccff", fontSize: "14px" }}>
				<tr>
					{headers.map((header, index) => (
						<th key={index} style={{ border: "1px solid black", padding: "4px 2px" }}>
							{header}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{eligibleMembers.map((member, rowIndex) => (
					<tr key={rowIndex} style={{ backgroundColor: rowIndex % 2 === 0 ? "#e6e6e6" : "white" }}>
						{headersWithValue.map((header, colIndex) => (
							<td
								key={colIndex}
								style={{
									border: "1px solid black",
									fontSize: "12px",
									textAlign: "center",
									whiteSpace: "nowrap" // Added this
								}}
							>
								{header === "INCFRMDT" || header === "INCTODT"
									? mongoDBDateParserToUSAFormat_v2(member[header as keyof EOBEligibleMembers])
									: header === "CODE" || header === "PERC" || header === "REMARK_CODE"
									? `${member[header as keyof EOBEligibleMembers]}`
									: addCommaSuffixToTableValue(`$ ${member[header as keyof EOBEligibleMembers]}`)}
							</td>
						))}
					</tr>
				))}
				<tr style={{ backgroundColor: eligibleMembers.length % 2 === 0 ? "#e6e6e6" : "white" }}>
					{headersWithValue.map((header, index) => (
						<td
							style={{
								border: "1px solid black",
								fontSize: "12px",
								fontWeight: "bold",
								textAlign: "center",
								backgroundColor: "#f1e6ff",
								whiteSpace: "nowrap" // Added this
							}}
							key={index}
						>
							{totalData[0][header as keyof ITotalData]}
						</td>
					))}
				</tr>
				{/* interface EOBEligibleMembers {
					// existing properties...
					discount: number;
				} */}

				{/* <tr>
					{totalData.map((data, index) => (
						<td key={index} style={{ border: "1px solid black", padding: "8px" }}>
							{numericColumns.includes(headers[index] as keyof EOBEligibleMembers)
								? data[headers[index] as keyof EOBEligibleMembers]
								: ""}
						</td>
					))}
				</tr> */}
			</tbody>
		</table>
	);
};

export default ExplanationOfBenefitsGridPrintv2;
