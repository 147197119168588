import React from "react";

import "./faq.css";
import { FREQUENTLY_ASKED_QUESTION } from "../../../constants/frequentlyAskedQuestions";

const FAQ: React.FC = () => {
	return (
		<section className="faq-section-main-conatiner" id="section2">
			<h3 className="subheadings">Why Nexcaliber?</h3>
			{/* <p className="subheadings-description">
				Stuck on something? We are here to help you with all your questions and answers in one place
			</p> */}

			{/* QUESTIONS GRID */}
			<div className="faq-grid-container">
				{FREQUENTLY_ASKED_QUESTION.map((question, index) => {
					const { ICON, question_title, question_desc } = question;
					return (
						<div key={index} className="question-container">
							<div className="faq-question-left-section">
								<div className="faq-icon-container">
									<ICON className="faq-icon" />
								</div>
							</div>

							<div className="faq-question-right-section">
								<span className="question-title">{question_title}</span>
								<span className="question-description">{question_desc}</span>
							</div>
						</div>
					);
				})}
			</div>
		</section>
	);
};

export default FAQ;
