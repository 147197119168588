/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { NEXCAILBER_QUERY_USERS } from "../../../constants/nexcaliberQueryUsers";

import "../../../global/globalStyles.css";

// eslint-disable-next-line arrow-parens, react/prop-types
const CustomContactForm = ({ onChangeHandler, formData, handleDropdownChange, handleSubmit, formErrors }) => {
	return (
		<form className="contact-form" onSubmit={handleSubmit}>
			<div className="contact-form-input-area">
				<div className="contact-form-name">
					{/* <div className="contact-form-name-content">
						<label>Name</label>
					</div> */}
					<input
						type="text"
						name="name"
						placeholder="Name*"
						onChange={onChangeHandler}
						value={formData.name}
					/>
					<div className="error-message-name">{formErrors.name}</div>
				</div>
				<div className="contact-form-organization">
					{/* <div className="contact-form-organization-content">
						<label>Organization</label>
					</div> */}
					<input
						type="text"
						name="organization"
						placeholder="Organization*"
						onChange={onChangeHandler}
						value={formData.organization}
					/>
					<div className="error-message-name">{formErrors.organization}</div>
				</div>

				<div className="contact-form-designation">
					{/* <div className="contact-form-designation-content">
						<label>Designation</label>
					</div> */}
					<input
						type="text"
						name="designation"
						placeholder="Designation"
						onChange={onChangeHandler}
						value={formData.designation}
					/>
					<div className="error-message-name">{formErrors.designation}</div>
				</div>
				<div className="contact-form-email">
					{/* <div className="contact-form-email-content">
						<label>Email</label>
					</div> */}
					<input
						type="text"
						name="email"
						placeholder="Email*"
						onChange={onChangeHandler}
						value={formData.email}
					/>
					<div className="error-message-name">{formErrors.email}</div>
				</div>

				<div className="contact-form-number">
					{/* <div className="contact-form-number-content">
						<label>Contact</label>
					</div> */}
					<input
						type="text"
						name="phone_number"
						placeholder="Contact Number*"
						onChange={onChangeHandler}
						value={formData.phone_number}
					/>
					<div className="error-message-name">{formErrors.phone_number}</div>
				</div>
				<div className="contact-form-dropdown">
					{/* <div className="contact-form-dropdown-content">
						<label>Who are you?</label>
					</div> */}
					<div className="user-details-container">
						<select name="dropdown" onChange={handleDropdownChange} value={formData.identification}>
							<option value="" disabled selected>
								You are?
							</option>
							{NEXCAILBER_QUERY_USERS.map((data, index) => {
								return (
									<option key={index} value={data.value}>
										{data.label}
									</option>
								);
							})}
						</select>
						<div className="error-message-name">{formErrors.identification}</div>
					</div>
				</div>
				<div className="contact-form-query">
					{/* <div className="contact-form-query-content">
						<label>Query</label>
					</div> */}
					<textarea
						type="text"
						name="query"
						rows="2"
						cols="7"
						placeholder="Write your Query here..."
						onChange={onChangeHandler}
						value={formData.query}
					/>
					<div className="error-message-name">{formErrors.query}</div>
				</div>
			</div>
			<div className="contact-form-submit">
				<button type="submit">Send Query</button>
			</div>
		</form>
	);
};

export default CustomContactForm;
