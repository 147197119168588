import USAMapCoordinates from "../../constants/usaMapCoordinates/usaMapCoordinates.json";

export const zipToCoordinateConverter = (ZIP: string): number[] => {
	const result: number[] = [];
	const USAMapCoordinatesMapper: any[any] = USAMapCoordinates;

	// if the length of the more than it will slice it down to 5 digits
	if (ZIP.length > 5) ZIP = ZIP.substring(0, 5);

	if (ZIP.length < 0 || ZIP === undefined || ZIP === null) {
		console.log("ZIP NULL OR ULTO PALTA");
	}

	USAMapCoordinatesMapper.forEach((coordinate: any, index: number) => {
		if (coordinate.zip_code === ZIP) {
			const { lat, lon } = coordinate.geo_point_2d;

			if (lat === null || lat === undefined) {
				console.log("checker", ZIP);
			}
			result.push(lat, lon);
		}
	});

	return result;
};
