import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useRef, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { mongoDBDateParserToUSAFormat_v2 } from "../../../../../utils/commonFunctions/dates";

const formatCharge = (chargeStr: string): string => {
	const charge = chargeStr.replace("$", "");
	const chargeFloat = parseFloat(charge);
	const chargeParts = charge.split(".");
	if (chargeParts.length === 1) {
		return `$ ${chargeFloat.toFixed(2)}`;
	} else if (chargeParts[1].length === 1) {
		return `$ ${chargeFloat.toFixed(2)}`;
	} else {
		return `$ ${chargeFloat.toFixed(2)}`;
	}
};

const ExplanationOfBenefitsGrid = ({ eligibleMembers = [], claimTotal }: any) => {
	console.log("DETAILS_______>", eligibleMembers);

	const formatPercentageToDollar = (value: string) => {
		let formattedValue = "$" + value;

		// Check if the value includes a decimal point
		if (!formattedValue.includes(".")) {
			formattedValue += ".00"; // Add ".00" if no decimal is present
		} else {
			const decimalPart = formattedValue.split(".")[1];
			if (decimalPart.length === 1) {
				formattedValue += "0"; // Add a "0" if there's only one digit after the decimal
			}
		}

		return formattedValue;
	};

	const newEligibleMember = eligibleMembers.map((member: any) => {
		if (member.PERC) {
			return {
				...member,
				// PERC: formatPercentageToDollar(member.PERC),
				CHGAMT: formatPercentageToDollar(member.CHGAMT),
				ADJCVRD: formatPercentageToDollar(member.ADJCVRD),
				PAYMENT: formatPercentageToDollar(member.PAYMENT),
				PRVRESP: formatPercentageToDollar(member.PRVRESP),
				DEDAMT: formatPercentageToDollar(member.DEDAMT),
				COPAMT: formatPercentageToDollar(member.COPAMT),
				NETEERESP: formatPercentageToDollar(member.NETEERESP)
			};
		}
		return member;
	});

	console.log("Formatted Eligible Members: ", newEligibleMember);
	const totalData =
		claimTotal && claimTotal.length > 0
			? [
					{
						CODE: "Total",
						INCFRMDT: "",
						INCTODT: "",
						CHGAMT: formatCharge(`${claimTotal[0]["TOTAL_CHARGE"]}`),
						NETEERESP: formatCharge(`${claimTotal[0]["TOTAL_NOTCOVERED"]}`),
						PRVRESP: formatCharge(`${claimTotal[0]["TOTAL_DISCOUNT"]}`),
						ADJCVRD: formatCharge(`${claimTotal[0]["TOTAL_ELIGIBLEEXPENSES"]}`),
						REMARK_CODE: "",
						COPAMT: formatCharge(`${claimTotal[0]["TOTAL_COPAY"]}`),
						DEDAMT: formatCharge(`${claimTotal[0]["TOTAL_DEDUCTIBLE"]}`),
						PERC: "",
						PAYMENT: formatCharge(`${claimTotal[0]["PAYMENT"]}`)
					}
			  ]
			: [];
	const [gridHeight, setGridHeight] = useState("400px");
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);

	const handleAgGridReady = (params: GridReadyEvent) => {
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};

	const handleDateCellRenderer = ({ value }: { value: string }) => {
		if (value) {
			// console.log("====>value", value);
			return mongoDBDateParserToUSAFormat_v2(value);
		}
		return null;
	};

	return (
		<div className="explanation-of-benefits-grid" id="explanation-of-benefits-grid">
			<div className="ag-grid-main-container">
				<div className="ag-theme-alpine" style={{ height: gridHeight, width: "100%" }}>
					<AgGridReact
						ref={agGridRef}
						rowData={newEligibleMember}
						onGridReady={handleAgGridReady}
						onFirstDataRendered={handleFirstDataRendered}
						pinnedBottomRowData={totalData}
						animateRows
						paginationPageSize={6}
						defaultColDef={{
							filter: "agTextColumnFilter",
							floatingFilter: true,
							resizable: true
						}}
						enableCellChangeFlash={true}
						defaultColGroupDef={{ marryChildren: true }}
						frameworkComponents={{ handleDateCellRenderer }}
						rowSelection={"single"}
					>
						<AgGridColumn
							field="CODE"
							headerName="TYPE OF SERVICE"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="INCFRMDT"
							headerName="SERVICE START DATE"
							suppressSizeToFit
							cellRenderer="handleDateCellRenderer"
							floatingFilterComponentParams={{ suppressFilterButton: true }}
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="INCTODT"
							headerName="SERVICE END DATE"
							suppressSizeToFit
							cellRenderer="handleDateCellRenderer"
							floatingFilterComponentParams={{ suppressFilterButton: true }}
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="CHGAMT"
							headerName="TOTAL CHARGE"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="NETEERESP"
							headerName="NOT COVERED OR PENALTY"
							floatingFilterComponentParams={{ suppressFilterButton: true }}
							suppressSizeToFit
							// cellStyle={() => {
							// 	return { cursor: "pointer" };
							// }}
							filter={false}
							// valueGetter={(params: any) => {
							// 	const data = params.data;
							// 	return data["(D.EERESP-(D.DEDAMT+D.COPAMT+D.COINAMT))"];
							// }}
						></AgGridColumn>
						<AgGridColumn
							field="PRVRESP"
							headerName="DISCOUNT"
							suppressSizeToFit
							// cellStyle={() => {
							// 	return { cursor: "pointer" };
							// }}
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="ADJCVRD"
							headerName="ELIGIBLE EXPENSE"
							suppressSizeToFit
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="REMARK_CODE"
							headerName="REMARK CODE"
							suppressSizeToFit
							// cellStyle={() => {
							// 	return { cursor: "pointer" };
							// }}
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="COPAMT"
							headerName="CO-PAY"
							suppressSizeToFit
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="DEDAMT"
							headerName="DEDUCTIBLE AMOUNT"
							suppressSizeToFit
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="PERC"
							headerName="PAID AT %"
							suppressSizeToFit
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="PAYMENT"
							headerName="BENEFITS PAID"
							suppressSizeToFit
							filter={false}
						></AgGridColumn>
					</AgGridReact>
				</div>
			</div>
		</div>
	);
};

export default ExplanationOfBenefitsGrid;
