import React from "react";
import "./coveredDependentsPrint.css";
const CoveredDependents = () => {
	return (
		<div className="summary-of-benefits-main-container-print">
			<div className="summary-of-benefits-header">Covered Dependents Must Be Age 8 or Older</div>
			<div className="summary-of-benefits-body">
				<div style={{ marginBottom: 30 }}>
					$0 fee for lab services ordered by Entira Clinic, Lufkin and performed at Clinical Pathology
					Laboratories (CPL labs). For CPL lab locations, please visit www.cpllabs.com .
				</div>
				<div style={{ marginBottom: 30 }}>
					Services performed outside of the specified Entira Clinic, Lufkin office, or outside of Entira
					Clinic, Lufkin TeleConnect virtual visits, are not covered under the Entira Clinic Card Program.
				</div>
				<div style={{ marginBottom: 10 }}>
					The Entira Clinic Card Program is not major medical coverage and does not meet the guidelines of
					minimum essential coverage according to Affordable Care Act regulations.
				</div>
			</div>
		</div>
	);
};

export default CoveredDependents;
