export const roleMapper = (SPOUSE: string, CHILD: string, OTHER: string): string => {
	let role = "";
	if (SPOUSE === "T") role = "Spouse";
	else if (CHILD === "T") role = "Child";
	else if (OTHER === "T") role = "Other";
	else if (
		(SPOUSE === "F" || SPOUSE === null) &&
		(CHILD === "F" || CHILD === null) &&
		(OTHER === "F" || OTHER === null)
	)
		role = "Employee";

	return role;
};
