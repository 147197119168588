import React from "react";
import "./summaryOfBenefits.css";
const SummaryOfBenefits = () => {
	return (
		<>
			<div className="summary-of-benefits-main-container">
				<div className="summary-of-benefits-header">Summary of Benefits for Medical Plan</div>
				<div className="summary-of-benefits-body">
					<div className="summary-of-benefits-sub-header">
						<div className="sub-header-in-network">In Network</div>
						<div className="sub-header-out-of-network">Out of Network</div>
					</div>
					<div className="body-container-top">
						<div className="column-container">
							<div className="first-column">Deductible - Ind/Fam</div>
							<div className="second-column">$1,500/$4,500</div>
							<div className="third-column">$3,000/$9,000</div>
						</div>
						<div className="column-container">
							<div className="first-column">Out-of-Pocket - Ind/Fam</div>
							<div className="second-column">$9,100 / $18,200</div>
							<div className="third-column">Unlimited</div>
						</div>
						<div className="column-container">
							<div className="first-column">Co-insurance</div>
							<div className="second-column">70% after deductible</div>
							<div className="third-column">40% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Office Visit</div>
							<div className="second-column">70% after deductible</div>
							<div className="third-column">40% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">2 Visits</div>
							<div className="second-column">$ 35 Co-pay</div>
							<div className="third-column"></div>
						</div>
					</div>
					<div className="body-container-bottom">
						<div className="column-container">
							<div className="first-column">Hospital Services</div>
							<div className="second-column">70% after Co-pay and ded</div>
							<div className="third-column">40% after Co-pay and ded</div>
						</div>
						<div className="column-container">
							<div className="first-column">Per Confinement Co-pay</div>
							<div className="second-column">$300 per Admission</div>
							<div className="third-column">$500 per Admission</div>
						</div>
						<div className="column-container">
							<div className="first-column">Emergency Room Co-pay</div>
							<div className="second-column">$100 per Incident</div>
							<div className="third-column">$100 per Incident</div>
						</div>
						<div className="column-container">
							<div className="first-column">Outpatients Services Co-pay</div>
							<div className="second-column">$100 per Incident</div>
							<div className="third-column">$100 per Incident</div>
						</div>
						<div className="column-container">
							<div className="first-column">Wellness Benefits</div>
							<div className="second-column">100%</div>
							<div className="third-column">40% after deductible</div>
						</div>
					</div>
				</div>
			</div>
			<div className="summary-of-benefits-body-mobile">
				<div className="summary-of-benefits-header">Summary of Benefits for Medical Plan</div>
				<div className="summary-of-benefits-body-top">
					{/* ================================================================================ */}
					<div className="primary-header">Deductible - Ind/Fam</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$1,500/$4,500</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">$3,000/$9,000</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Out-of-Pocket - Ind/Fam</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$9,100 / $18,200</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">Unlimited</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Co-insurance</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">70% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">40% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Office Visit</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">70% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">40% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">2 Visit</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$ 35 Co-pay</div>
					</div>
					<div className="info-container"></div>
				</div>
				<div className="summary-of-benefits-body-bottom">
					{/* ================================================================================ */}
					<div className="primary-header">Hospital Services</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">70% after Co-pay and ded</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">40% after Co-pay and ded</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Per Confinement Co-pay</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$300 per Admission</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">$500 per Admission</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Emergency Room Co-pay</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$100 per Incident</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">$100 per Incident</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Outpatients Services Co-pay</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$100 per Incident</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">$100 per Incident</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Wellness Benefits</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">100%</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">40% after deductible</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SummaryOfBenefits;
