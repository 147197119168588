import React from "react";

interface CursorProps {
	isVisible: boolean;
}

const Cursor: React.FC<CursorProps> = ({ isVisible }) => {
	return <span style={{ opacity: isVisible ? 1 : 0 }}>|</span>;
};

export default Cursor;
