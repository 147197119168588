/* eslint-disable react/prop-types */
import React, { LegacyRef, RefAttributes, forwardRef } from "react";

import { BiSolidReport } from "react-icons/bi";
import { HiBadgeCheck } from "react-icons/hi";
import PropTypes from "prop-types";

import { Chip } from "@mui/material";

import { FaClipboardList, FaExclamationCircle, FaUserCheck, FaUserEdit } from "react-icons/fa";
import { NEXCALIBER_DETAILS } from "../../../../../constants/nexcaliberDetails";
import { BrookshireBrothersCircleLogo, NexcaliberPurpleLogo } from "../../../../../assets";
import { TbFileReport } from "react-icons/tb";
import "./explanationOfBenefitsFormPrintv2.css";
import { mongoDBDateParserToUSAFormat_v2 } from "../../../../../utils/commonFunctions/dates";

import Remarks from "../remarks/Remarks";
import { formatSSN } from "../../../../../utils/commonFunctions/SSNMasking";
import ExplanationOfBenefitsGridPrintv2 from "./ExplanationOfBenefitGridPrintV2/ExplanationOfBenefitGridPrintv2";
import Remarksv2 from "../remarksv2/Remarksv2";

const ExplanationOfBenefitFormPrintV2: React.ForwardRefExoticComponent<any> = forwardRef(
	// eslint-disable-next-line react/prop-types
	({ eobDetails, groupNumber }, ref): JSX.Element => {
		console.log("eobDetails", eobDetails);
		console.log("groupNumber=====>>>>>", groupNumber);

		return (
			<div className="printable-EOB" ref={ref as unknown as LegacyRef<HTMLDivElement> | undefined}>
				<div className="EOB-paper-container" style={{ padding: "20px 50px" }}>
					{/* ============================= HEADER CONTAINER ================================= */}
					<div className="header-container">
						<h1 style={{ fontSize: "20px", marginLeft: "10px" }}>
							<span>
								{groupNumber === 220
									? "BROOKSHIRE BROTHERS, INC."
									: groupNumber === 188
									? "INTERCITY INVESTMENT, INC."
									: groupNumber === 225
									? "PANHANDLE FIRST BANK."
									: groupNumber === 300
									? "PROGRESSIVE LABORATORIES, INC."
									: null}{" "}
							</span>{" "}
							*ADMINISTERED BY NEXCALIBER
						</h1>
					</div>
					{/* <div className="subheading-main">
						<HiBadgeCheck />
						Explanation of Benefits
					</div> */}
					{/* ============================= BODY CONTAINER ====================================*/}
					<div className="main-grid-container">
						<div className="header-left">
							<strong>NEXCALIBER</strong>
							<p>
								P O BOX 802422
								<br />
								DALLAS, TX 75380-2422
							</p>
						</div>
						<div className="header-right">
							<strong>EXPLANATION OF BENEFITS</strong>
							<p>Brookshire Brothers, Inc.</p>
							<div className="claim-details">
								<p>
									<span>Group#:</span>
									00{groupNumber}
								</p>
								<p>
									<span>Check Number #:</span>{" "}
									{eobDetails?.claimantSubscriberDetailsInstance[0].CHECKNO
										? eobDetails?.claimantSubscriberDetailsInstance[0].CHECKNO
										: "N/A"}
								</p>
								<p>
									<span>Date:</span>{" "}
									{eobDetails?.claimantSubscriberDetailsInstance[0].PDDT ? (
										<span className="field-value">
											{mongoDBDateParserToUSAFormat_v2(
												eobDetails?.claimantSubscriberDetailsInstance[0].PDDT
											)}
										</span>
									) : null}
								</p>
								<p>
									<span>Insured:</span>
									{eobDetails?.claimantSubscriberDetailsInstance[0].INSURED_NAME}
								</p>
								<p>
									<span>Claim For:</span>{" "}
									{eobDetails?.claimantSubscriberDetailsInstance[0].PATIENT_NAME}
								</p>
								<p>
									<span>Patient Acct:</span>{" "}
									{eobDetails?.claimantSubscriberDetailsInstance[0].PATACCT}
								</p>
								<p>
									<span>Claim No #:</span>
									{eobDetails?.claimantSubscriberDetailsInstance[0].CLAIM_NO}
								</p>
							</div>
						</div>
						{eobDetails?.payeeDetailsInstance?.[0] && eobDetails?.payeeDetailsInstance?.length !== 0 ? (
							<>
								<div className="temp-return">
									<strong>Payee Details </strong>{" "}
									<span style={{ fontWeight: "bold" }}>
										({eobDetails?.payeeDetailsInstance[0].PAYTO === "P" ? "Provider" : "Employee"})
									</span>
									<p>
										<span>{eobDetails?.payeeDetailsInstance[0].PAYEE}</span>
										<br />
										{eobDetails?.payeeDetailsInstance[0].ADDR1}
										<br />
										{eobDetails?.payeeDetailsInstance[0].CITY}
										{","} {eobDetails?.payeeDetailsInstance[0].STATE}{" "}
										{eobDetails?.payeeDetailsInstance[0].ZIP}
									</p>
								</div>
							</>
						) : null}
						<div className="claim-summary">
							<strong>PROVIDER(S)</strong>
							<p>
								<span>{eobDetails?.providerDetailsInstance[0].NAME}</span>
								{/* <span>
									{eobDetails?.providerDetailsInstance[0].ADDR1}
									<br />
									{eobDetails?.providerDetailsInstance[0].CITY}
									{","} {eobDetails?.providerDetailsInstance[0].STATE}{" "}
									{eobDetails?.providerDetailsInstance[0].ZIPCODE}
								</span> */}
								<br />
								{/* <span>
									{eobDetails?.providerDetailsInstance[0].PRVNO} -{" "}
									{eobDetails?.providerDetailsInstance[0].IDSUFFIX}
								</span> */}
								<span>{eobDetails?.PPODetailsInstance[0].PPO_DETAILS}</span>
							</p>

							<div className="claim-details-second">
								<strong>CLAIM SUMMARY</strong>
								<p>
									<span>TOTAL COVERED AMT:</span> ${" "}
									{eobDetails?.claimSummaryInstance[0]["SUM_ADJCVRD"] === 0
										? "0.00"
										: eobDetails?.claimSummaryInstance[0]["SUM_ADJCVRD"]}
								</p>
								<p>
									<span>Paid by Other Insurance Company:</span> ${" "}
									{eobDetails?.claimSummaryInstance[0]["SUM_COBAMT"] === 0
										? "0.00"
										: eobDetails?.claimSummaryInstance[0]["SUM_COBAMT"]}
								</p>
								<p>
									<span>Total Paid By Plan:</span> ${" "}
									{eobDetails?.claimSummaryInstance[0]["SUM_PLNRESP"] === 0
										? "0.00"
										: eobDetails?.claimSummaryInstance[0]["SUM_PLNRESP"]}
								</p>

								<p>
									<span>Employees Responsibility:</span> ${" "}
									{eobDetails?.claimSummaryInstance[0]["SUM_EERESP"] === 0
										? "0.00"
										: eobDetails?.claimSummaryInstance[0]["SUM_EERESP"]}
								</p>
								<p>
									<span>Out Of Pocket Remaining Plan:</span>${" "}
									{eobDetails?.deductibleInstance[0]["OOPREMPLN"] === 0
										? "0.00"
										: eobDetails?.deductibleInstance[0]["OOPREMPLN"]}
								</p>
								{/* <p>
									<span>Annual Accumulation:</span>${" "}
									{eobDetails?.annualAccumulationInstance[0]["ANN1MET"]}
								</p> */}
							</div>
						</div>
					</div>
					{/* ============================= CLAIM DETAILS ====================================*/}
					{eobDetails?.claimDetailsInstance.length > 8 ? (
						<>
							<div className="page-break"></div>
							{/* <div className="subheading-main">
								<BiSolidReport />
								Claim Details
							</div> */}

							<ExplanationOfBenefitsGridPrintv2
								eligibleMembers={eobDetails?.claimDetailsInstance}
								claimTotal={eobDetails?.claimTotalInstance}
							/>
						</>
					) : (
						<>
							{/* <div className="subheading-main">
								<BiSolidReport />
								Claim Detailssss
							</div> */}

							<ExplanationOfBenefitsGridPrintv2
								eligibleMembers={eobDetails?.claimDetailsInstance}
								claimTotal={eobDetails?.claimTotalInstance}
							/>
						</>
					)}

					{/* ============================= REMARKS ====================================*/}
					{eobDetails?.claimDetailsInstance.length >= 1 ? (
						<>
							{/* <div className="page-break"></div> */}
							{/* <div className="subheading-main" style={{ marginTop: "40px" }}>
								<TbFileReport />
								Remarkswww
							</div> */}
							{/* <p style={{ backgroundColor: "#d9d9d9", padding: 2, width: "fit-content" }}>
								{eobDetails?.messageLineInstance[0].FFMSG}
							</p> */}
							<div style={{ marginTop: "30px" }}>
								<Remarksv2
									remarks_list={eobDetails?.explanationCodesInstance}
									key={0}
									remark_id={eobDetails?.messageLineInstance[0].FFMSG}
								/>
							</div>
							<p
								style={{
									fontSize: "12px",
									// fontStyle: "italic",
									color: "#6c757d",
									lineHeight: "1.5",
									marginTop: "20px"
								}}
							>
								This statement is an explanation of how the expenses you submitted were processed in
								accordance with your Group Benefit Plan. If a remark comment was indicated, please
								provide the information necessary to complete your claim. The Employee Retirement Income
								Security Act of 1974 (ERISA) provides you with the right to appeal a claim denial. If
								you do not agree with the reason your claim was denied in whole or in part, you should
								write to us within 60 days or longer if permitted under your Group Benefit Plan. You
								should state your position and include any additional information that might be relevant
								to the review of the matter. All information will be reviewed, and you will be informed
								of the decision in a timely manner.
							</p>
						</>
					) : (
						<>
							<div style={{ marginTop: "50px" }}>
								<br />
							</div>
							<div className="subheading-main" style={{ marginTop: "40px" }}>
								<TbFileReport />
								Remarks
							</div>
							<p style={{ backgroundColor: "#d9d9d9", padding: 2, width: "fit-content" }}>
								{eobDetails?.messageLineInstance[0].FFMSG}
							</p>
							<Remarks remarks_list={eobDetails?.explanationCodesInstance} key={0} />
						</>
					)}
				</div>
			</div>
		);
	}
);

ExplanationOfBenefitFormPrintV2.displayName = "EOBPrint";
ExplanationOfBenefitFormPrintV2.propTypes = {
	eligibleMembers: PropTypes.any.isRequired
};

export default ExplanationOfBenefitFormPrintV2;
