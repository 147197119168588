import { FiPhoneCall } from "react-icons/fi";
import { ContactConstant } from "../@types/contact.types";
import { AiOutlineMail } from "react-icons/ai";

export const CONTACTS: ContactConstant[] = [
	{
		icon: () => <FiPhoneCall className="icon-phone" />,
		contact_label: "Local",
		contact_value: "(972) 248-1011",
		type: "phone"
	},
	{
		icon: () => <FiPhoneCall className="icon-phone" />,
		contact_label: "Toll Free",
		contact_value: "(800) 741-0185",
		type: "phone"
	},
	{
		icon: () => <FiPhoneCall className="icon-phone" />,
		contact_label: "Claims Fax",
		contact_value: "(972) 248-1405",
		type: "fax"
	},
	{
		icon: () => <FiPhoneCall className="icon-phone" />,
		contact_label: "Admin Fax",
		contact_value: "(972) 248-4728",
		type: "fax"
	}
	// {
	// 	icon: () => <AiOutlineMail className="icon-mail" />,
	// 	contact_label: "IDR",
	// 	contact_value: "qpa-idr@nexcaliber.com",
	// 	type: "mail"
	// }
];
