import { FiHelpCircle } from "react-icons/fi";
import { FrequentlyAskedQuestion } from "../@types/frequentlyAskedQuestions.types";

export const FREQUENTLY_ASKED_QUESTION: FrequentlyAskedQuestion[] = [
	{
		ICON: FiHelpCircle,
		question_title: "Why Nexcaliber over another TPA?",
		question_desc:
			"You are working with a boutique administrator that has decades of experience with all size groups from small to 2,500 lives so we know how to set up plans that allow you more control for less expense and we share the risk."
	},
	{
		ICON: FiHelpCircle,
		question_title: "Why is Nexcaliber less expensive?",
		question_desc:
			"Three reasons. First, it provides a closed-loop, automated system that channels claims through a simple, in-house system and settles them with aggressive risk-share (savings of claims) management. Next, We have negotiated rates not too much above Medicare for our networks. Finally, clients are royalty and we’re not greedy."
	}
];
