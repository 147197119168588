import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L, { MarkerCluster } from "leaflet";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";

import "./customMapView.css";
import { useEffect, useState } from "react";

import React from "react";
import ReactDOMServer from "react-dom/server";
import { zipToCoordinateConverter } from "../../../../../utils/commonFunctions/zipToCoordinateConverter";

interface CustomMarkerIconProps {
	borderColor: string;
	fillColor: string;
	circleColor: string;
	height: string;
}

const CustomMarkerIcon: React.FC<CustomMarkerIconProps> = ({ borderColor, fillColor, circleColor, height }) => (
	<svg width={"32"} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1">
		<g transform="translate(0 -1028.4)">
			<path
				d="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z"
				transform="translate(0 1028.4)"
				fill={fillColor}
				stroke={borderColor}
				strokeWidth="2"
			/>
			<circle cx="12" cy="10" r="3" fill={circleColor} stroke={fillColor} strokeWidth="2" />
		</g>
	</svg>
);

const createCustomIcon = (borderColor: string, fillColor: string, circleColor: string, height: string) => {
	const iconMarkup = ReactDOMServer.renderToString(
		<CustomMarkerIcon borderColor={borderColor} fillColor={fillColor} circleColor={circleColor} height={height} />
	);
	const customIcon = L.divIcon({
		className: "",
		html: iconMarkup
	});
	return customIcon;
};

export type ProvidersCustomMapView = {
	markers: Markers[];
	userZipCode: any;
};
type Markers = {
	miles: string;
	positionProps: number[];
	nameProps: string;
};
const calculateCentroid = (points: [number, number][]): [number, number, number, number] => {
	if (points.length === 0) {
		return [0, 0, 0, 0]; // Return a default value if there are no points
	}

	let minLat = Infinity;
	let maxLat = -Infinity;
	let minLon = Infinity;
	let maxLon = -Infinity;

	// Find the bounding box
	points.forEach((point: [number, number]) => {
		minLat = Math.min(minLat, point[0]);
		maxLat = Math.max(maxLat, point[0]);
		minLon = Math.min(minLon, point[1]);
		maxLon = Math.max(maxLon, point[1]);
	});

	// Calculate the center of the bounding box
	const centroidLat = (minLat + maxLat) / 2;
	const centroidLon = (minLon + maxLon) / 2;

	return [centroidLat, centroidLon, Math.abs(minLon - maxLon), Math.abs(minLat - maxLat)];
};

const createClusterCustomIcon = function (cluster: MarkerCluster) {
	return new L.DivIcon({
		html: `<span>${cluster.getChildCount()}</span>`,
		className: "custom-marker-cluster",
		iconSize: L.point(33, 33, true)
	});
};

const CustomMap: React.FC<ProvidersCustomMapView> = ({ markers, userZipCode }): JSX.Element => {
	useEffect(() => {
		window.scrollTo(0, 0);
	});

	const customRedIcon = createCustomIcon("red", "red", "white", "50");
	const customBlueIcon = createCustomIcon("blue", "blue", "white", "34");
	const customGreenIcon = createCustomIcon("green", "green", "white", "42");
	// const EntiraClinic = markers[0];
	const [EntiraClinic, setEntiraClinic] = useState(markers[0]);
	const [firstTenLocation, setFirstTenLocation] = useState<Markers[]>([]);
	// markers.shift();
	const [marking, setMarking] = useState<Markers[]>([]);

	const [mouseZoom, seMouseZoom] = useState<number>(6);

	const userCoordinates = zipToCoordinateConverter(userZipCode);
	const calculatingCentoidPoints = [];
	markers.forEach((item: Markers) => {
		calculatingCentoidPoints.push(item.positionProps);
	});
	calculatingCentoidPoints.push(userCoordinates);
	const centroid = calculateCentroid(calculatingCentoidPoints as unknown as [number, number][]);

	console.log("centroid", centroid);
	console.log("userCoordinates", userCoordinates);
	console.log("userZipCode", userZipCode);
	console.log("entira", EntiraClinic.positionProps);

	const maxHeight = Math.floor(18.2 / centroid[2]) === 0 ? 1 : Math.floor(18.2 / centroid[2]);
	const maxWidth = Math.floor(78.75 / centroid[3]) === 0 ? 1 : Math.floor(78.75 / centroid[3]);
	console.log("maxWidth", maxWidth);
	console.log("maxHeight", maxHeight);

	useEffect(() => {
		const wheel = maxHeight > maxWidth ? maxWidth : maxHeight;
		seMouseZoom(wheel);

		const markersCopy = [...markers];

		const tenLocation = markersCopy.slice(1, 11);

		const remainingMarkers = markersCopy.slice(11);

		setFirstTenLocation(tenLocation);

		setMarking(remainingMarkers);
	}, [markers]);
	return (
		<>
			<div className="map-supporting-containers-map"></div>
			<MapContainer
				center={[centroid[0], centroid[1]]} // Set the initial center to the first coordinates
				zoom={mouseZoom}
				style={{ width: "calc(100% - 63px" }}
			>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				/>
				{userCoordinates && userCoordinates.length > 0 && (
					<Marker
						key={userCoordinates[0]}
						position={[userCoordinates[0], userCoordinates[1]]}
						icon={customRedIcon}
						zIndexOffset={20}
					>
						<Popup>
							<div>
								<h2>Your location</h2>
							</div>
						</Popup>
					</Marker>
				)}

				{EntiraClinic.positionProps.length > 0 && (
					<Marker
						key={EntiraClinic.positionProps[0]}
						position={[EntiraClinic.positionProps[0], EntiraClinic.positionProps[1]]}
						icon={customGreenIcon}
						zIndexOffset={21}
					>
						<Popup>
							<div>
								<h3>{EntiraClinic.nameProps}</h3>
							</div>
						</Popup>
					</Marker>
				)}
				{firstTenLocation.map((marker, index) => (
					<Marker
						key={index}
						position={[marker.positionProps[0], marker.positionProps[1]]}
						icon={customBlueIcon}
					>
						<Popup>
							<div>
								<h3>{marker.nameProps}</h3>
								<p>
									{marker.miles} {"miles"}
								</p>
							</div>
						</Popup>
					</Marker>
				))}
				<MarkerClusterGroup
					chunkedLoading
					iconCreateFunction={createClusterCustomIcon}
					maxClusterRadius={150}
					spiderfyOnMaxZoom={true}
					polygonOptions={{
						fillColor: "#ffffff",
						color: "#f00800",
						weight: 5,
						opacity: 1,
						fillOpacity: 0.8
					}}
					showCoverageOnHover={true}
				>
					{marking.map((marker, index) => (
						<Marker
							key={index}
							position={[marker.positionProps[0], marker.positionProps[1]]}
							icon={customBlueIcon}
						>
							<Popup>
								<div>
									<h3>{marker.nameProps}</h3>
									<p>
										{marker.miles} {"miles"}
									</p>
								</div>
							</Popup>
						</Marker>
					))}
				</MarkerClusterGroup>
			</MapContainer>
		</>
	);
};

export default CustomMap;
