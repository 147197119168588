import "./summaryOfBenefits.css";
const SummaryOfBenefitsICI = () => {
	return (
		<>
			<div className="summary-of-benefits-main-container">
				<div className="summary-of-benefits-header">Summary of Benefits for Medical Plan</div>
				<div className="summary-of-benefits-body">
					<div className="summary-of-benefits-sub-header">
						<div className="sub-header-in-network">In Network</div>
						<div className="sub-header-out-of-network">Out of Network</div>
					</div>
					<div className="body-container-top">
						<div className="column-container">
							<div className="first-column">Deductible - Ind/Fam</div>
							<div className="second-column">$750 / $1,500</div>
							<div className="third-column">
								Out of network providers are included in the same deductible amount.
							</div>
						</div>
						<div className="column-container">
							<div className="first-column">Out-of-Pocket - Ind/Fam</div>
							<div className="second-column">$9,100 / $18,200</div>
							<div className="third-column">
								Out of network providers are included in the same deductible amount.
							</div>
						</div>
						<div className="column-container">
							<div className="first-column">Co-insurance</div>
							<div className="second-column">80% after deductible</div>
							<div className="third-column">80% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Office Visit</div>
							<div className="second-column">80% after deductible</div>
							<div className="third-column">80% after deductible</div>
						</div>
					</div>
					<div className="body-container-bottom">
						<div className="column-container">
							<div className="first-column">Hospital Services</div>
							<div className="second-column">80% after deductible</div>
							<div className="third-column">80% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Emergency Room and All related charges</div>
							<div className="second-column">80% after deductible</div>
							<div className="third-column">80% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Outpatients Services</div>
							<div className="second-column">80% after deductible</div>
							<div className="third-column">80% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Wellness Benefits</div>
							<div className="second-column">100%</div>
							<div className="third-column">100%</div>
						</div>
					</div>
				</div>
			</div>
			<div className="summary-of-benefits-body-mobile">
				<div className="summary-of-benefits-header">Summary of Benefits for Medical Plan</div>
				<div className="summary-of-benefits-body-top">
					{/* ================================================================================ */}
					<div className="primary-header">Deductible - Ind/Fam</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$750 / $1,500</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">
							Out of network providers are included in the same deductible amount.
						</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Out-of-Pocket - Ind/Fam</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$9,100 / $18,200</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">
							Out of network providers are included in the same deductible amount.
						</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Co-insurance</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Office Visit</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
				</div>
				<div className="summary-of-benefits-body-bottom">
					{/* ================================================================================ */}
					<div className="primary-header">Hospital Services</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Emergency Room and All related charges</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Outpatients Services</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">80% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Wellness Benefits</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">100%</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">100%</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SummaryOfBenefitsICI;
