import { FC } from "react";

import "./callToAction.css";
import TypewriterEffect from "../../../shared/TypewriterEffect";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CallToAction: FC = (): JSX.Element => {
	const navigate = useNavigate();

	return (
		<div className="call-to-action advantage">
			<div className="call-header">
				<div className="item-1">
					<div className="webkit-fill">
						<span>With Nexcaliber, We Share Your</span> <span className="mobile-risk">&nbsp;RISK</span>{" "}
					</div>
					<div className="water-wave-container" style={{ display: "block" }}>
						<h2 className="water-wave">RISK</h2>
						<h2 className="water-wave">RISK</h2>
					</div>
				</div>
				<div className="item-2">
					<button
						className="secondary-bttn partner-bttn"
						style={{ marginRight: "3rem", marginTop: "-250px" }}
						onClick={() => navigate("/contact")}
					>
						Partner Up
					</button>
				</div>
			</div>
		</div>
	);
};

export default CallToAction;
