import { useEffect, useState } from "react";
import useQuery from "./useQuery";

export type UseRouteParamProps = string;

const useRouteParam = (searchString: UseRouteParamProps): string | number | undefined | null => {
	const [routeParam, setRouteParam] = useState<string | number>();
	const query = useQuery();

	useEffect(() => {
		if (query && query.get(searchString)) {
			if (searchString === "qs") {
				setRouteParam(String(window.atob(query.get("qs") as string)) as string);
			} else if (searchString === "gn") {
				setRouteParam(Number(query.get("gn")) as number);
			} else if (searchString === "ln") {
				setRouteParam(Number(query.get("ln")) as number);
			} else if (searchString === "db") {
				setRouteParam(String(window.atob(query.get("db") as string)) as string);
			} else {
				setRouteParam(query.get(searchString) as string);
			}
		}
	}, [query, searchString]);

	return routeParam ? routeParam : null;
};

export default useRouteParam;
