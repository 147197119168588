export const NEXCALIBER_ADVANTAGE_DATA = [
	{
		tabheader: "Proprietary Network",
		heading: "Unite Your Domain. Discover the Power of a Proprietary Network",
		title: "Nexcaliber's proprietary network puts plan design, provider selection, and bill negotiation exactly where you want them — in the palm of your hand.",
		description:
			"Which means you get more than just a great service mix. You can contract services directly. Choose the physicians, hospitals, and benefits that best suit your group's needs. Enjoy the direct resolution of every claim that comes through your doors — at prices dramatically lower than those you're paying now. In a word, Nexcaliber means control. Total control of your entire healthcare benefits system — with no out-of-network suppliers to slow or complicate the process.",
		postscript: "So cut to the bottom line. Consolidate Your Power."
	},
	{
		tabheader: "Bill Negotiation",
		heading: "Bring Providers, Services and Bill Negotiation. Together Under the Same Flag.",
		title: "Want to reduce claims payments? Gain more leverage over pricing? For many employers we are repricing bills only percentages above Medicare!",
		description:
			"We settle invoices in days instead of weeks because we have our own claims processing staff. The risk-share management system that reprices and processes every claim in your network in one smooth, automated pass. With Nexcaliber, there’s no outsourcing. No paper shuffling. No out-of-network administrative hitches or costly time delays. Just big discounts we have already negotiated for swift claims settlement and pricing that makes paying retail a thing of the past.",
		postscript: "Take Control of your Bill Negotiation and sRepricing with Nexcaliber."
	},
	{
		tabheader: "Plan Design",
		heading: "Wield Influence Over Your Plan Design",
		title: "Control your choices and control your bottom line. It's that novel.",
		description:
			"With Nexcaliber’s unique plan design options, we address your group's specific needs under the Affordable Care Act (ACA). We guide you on essential coverage while allowing flexibility. Choose preferred hospitals and physicians, set wellness incentives, and design intervention programs within a cost-effective proprietary network. Our self-funding approach combines PPO benefits without the usual complexities, saving money through strategic claim challenges and repricing. It's empowerment for the self-sufficient.",
		postscript: "Nexcaliber. The better choice for health risk management."
	},
	{
		tabheader: "Entira Clinic",
		heading: "Workplace Wellness With Community Spirit",
		title: "The Entira Clinic is on standby for all healthcare needs of your employees!",
		description:
			"We have a robust team of highly skilled nurse practitioners, physicians, and specialists, including women’s health and dietary clinicians. Personal patient advocates simplify employees' lives by answering their calls, scheduling same-day virtual or in-person appointments, identifying top-notch specialists when recommended, and seamlessly booking referral and lab appointments. Our cost-effective services help our clients maximize the health of their employees and reduce their annual medical expenses.",
		postscript: "We focus on you!"
	}
];
