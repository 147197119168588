export const relationshipMapper = (SPOUSE: string, CHILD: string, OTHER: string): string => {
	let relationship = "";
	if (SPOUSE === "T") relationship = "Spouse";
	else if (CHILD === "T") relationship = "Child";
	else if (OTHER === "T") relationship = "Other";
	else if (
		(SPOUSE === "F" || SPOUSE === null) &&
		(CHILD === "F" || CHILD === null) &&
		(OTHER === "F" || OTHER === null)
	)
		relationship = "Self";

	return relationship;
};
