/* eslint-disable react/prop-types */
import React from "react";

import "./customAccordion.css";

const CustomAccordion = ({ activeTab, handleTabClick, data }) => {
	return (
		<div className="custom-accordion">
			{data.map((item, index) => (
				<div key={index} className={`custom-accordion-items ${activeTab === index ? "active" : ""}`}>
					<div className="custom-accordion-tab-header">
						<button onClick={() => handleTabClick(index)}>{item.tabheader}</button>
					</div>
					{activeTab === index && (
						<div className="custom-accordion-content">
							{/* <div className="custom-accordion-content-image"></div> */}
							<h2 className="custom-accordion-content-heading">{item.heading}</h2>
							<div className="custom-accordion-content-wrapper">
								<strong className="custom-accordion-content-title">{item.title}</strong>
								<p className="custom-accordion-content-description">{item.description}</p>
								<strong className="custom-accordion-content-postscript">{item.postscript}</strong>
							</div>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default CustomAccordion;
