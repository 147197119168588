import React from "react";
import "./hero.css";
import { BannerVideo, BannerVideo2, BannerSample1 } from "../../../assets";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TypewriterEffect from "../TypewriterEffect";

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 16000, min: 3000 },
		items: 1,
		paritialVisibilityGutter: 0
		//slidesToSlide: 3
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
		paritialVisibilityGutter: 0
		//slidesToSlide: 3
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		paritialVisibilityGutter: 0,
		slidesToSlide: 1
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};

const Hero: React.FC = () => {
	return (
		<section className="hero-section-main-container">
			<div className="hero-content">
				<Carousel
					responsive={responsive}
					partialVisible={false}
					swipeable
					showDots={false}
					arrows={false}
					autoPlay={false}
					rewindWithAnimation
					autoPlaySpeed={2000}
					infinite={false}
				>
					<div key={1}>
						<div className="hero-content-primary">Self Reliance </div>
						<div className="hero-content-secondary">
							<TypewriterEffect text="is your Best Armour" delay={100} />
						</div>
						<div className="hero-description">Join forces with us to enjoy operational autonomy</div>
						{/* <button className="btn">Click here</button> */}
					</div>
					<div key={2}>
						<div className="hero-content-primary">With Nexcaliber </div>
						<div className="hero-content-secondary">
							We share <span>Your</span> Risk
						</div>
						<div className="hero-description">
							We share Risk with Our Clients as We Earn a Percentage of Savings of the Benefit Outlay
							while Providing the below-mentioned services.
						</div>
					</div>
				</Carousel>
			</div>
		</section>
	);
};

export default Hero;
