import React, { useState } from "react";
import { BrookshireBrothersCircleLogo, NexcaliberPurpleLogo, GroupLogoSample } from "../../../assets";
import { NEXCALIBER_DETAILS } from "../../../constants/nexcaliberDetails";
import { FaClipboardList, FaExclamationCircle, FaUserCheck, FaUserEdit, FaPlus } from "react-icons/fa";
import { HiBadgeCheck } from "react-icons/hi";
import { BiSolidReport } from "react-icons/bi";
import { TbFileReport } from "react-icons/tb";

import "./explanationOfBenefits.css";

import Skeleton from "@mui/material/Skeleton";

const ExplanationOfBenefitsSkeleton: React.FC = (): JSX.Element => {
	return (
		<>
			<div className="EOB-main" id="EOB-main">
				<span className="theme-header-1 header">Explanation Of Benefits (EOB)</span>
				<div className="EOB-paper-container">
					{/* ============================= HEADER CONTAINER ================================= */}
					<div className="header-container">
						<div className="group-logo-container">
							<img src={GroupLogoSample} className="group-logo" alt="" />
						</div>
						<div className="nexcaliber-logo-container">
							<img src={NexcaliberPurpleLogo} className="nexcaliber-logo" alt="" />
							<div className="nexcaliber-location-details">
								<span>Nexcaliber</span>
								<span>PO BOX 802422, Dallas Texas 7530</span>
								<span>{NEXCALIBER_DETAILS.NEXCALIBER_CONTACT}</span>
							</div>
						</div>
					</div>
					<div className="subheading-main">
						<HiBadgeCheck />
						Explanation of Benefits
					</div>
					{/* ============================= BODY CONTAINER ====================================*/}
					<div className="eob-grid-container">
						<div className="eob-grid-item">
							<div className="category-heading">
								<FaUserCheck />
								<span>Subscriber</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Name</span>
								<span className="field-value">
									<Skeleton animation="wave" width={200} height={40} />
								</span>
							</div>
							<span className="grid-item-fields">
								<div className="grid-item-fields">
									<span className="field-title">Address</span>
									<span className="field-value">
										<Skeleton animation="wave" width={250} height={40} />
									</span>
								</div>
							</span>
						</div>
						<div className="eob-grid-item">
							<div className="category-heading">
								<FaUserEdit />
								<span>Claimant</span>
							</div>
							<div className="claimant-container">
								<div className="left-section">
									<div className="grid-item-fields">
										<span className="field-title">Claim For</span>
										<span className="field-value">
											<Skeleton animation="wave" width={200} height={40} />
										</span>
									</div>
									{/* <div className="grid-item-fields">
									<span className="field-title">SSN</span>
									<span className="field-value">
										{eobDetails?.claimantSubscriberDetailsInstance[0].SSN
											? formatSSN(String(eobDetails?.claimantSubscriberDetailsInstance[0].SSN))
											: null}
									</span>
								</div> */}

									<div className="grid-item-fields">
										<span className="field-title">Patient Account Number :</span>
										<span className="field-value">
											<Skeleton animation="wave" width={200} height={40} />
										</span>
									</div>

									<div className="grid-item-fields">
										<span className="field-title">Claim Number :</span>
										<span className="field-value">
											<Skeleton animation="wave" width={200} height={40} />
										</span>
									</div>
								</div>
								<div className="right-section">
									<div className="grid-item-fields">
										<span className="field-title">Check Number:</span>
										<span className="field-value">
											<Skeleton animation="wave" width={200} height={40} />
										</span>
									</div>

									<div className="grid-item-fields">
										<span className="field-title">Paid Date :</span>
										<Skeleton animation="wave" width={200} height={40} />
									</div>
								</div>
							</div>
						</div>
						<div className="eob-grid-item">
							<div className="category-heading">
								<FaClipboardList />
								<span>Provider Details</span>
							</div>
							<span>
								<Skeleton animation="wave" width={200} height={40} />
							</span>
							<span>
								<Skeleton animation="wave" width={200} height={40} />
								<br />
								<Skeleton animation="wave" width={200} height={40} />
							</span>
							<br />
							<span>
								<Skeleton animation="wave" width={200} height={40} />
							</span>
							<br />
							<div className="category-heading">
								<FaClipboardList />
								<span>PPO Details</span>
							</div>
							<span>
								<Skeleton animation="wave" width={200} height={40} />
							</span>
							<br />
							<br />
							<div className="category-heading">
								<FaClipboardList />
								<span>Payee Details</span>
							</div>
							<span>
								<Skeleton animation="wave" width={200} height={40} />
							</span>
							<span>
								<Skeleton animation="wave" width={200} height={40} />
								<br />
								<Skeleton animation="wave" width={200} height={40} />
							</span>
						</div>
						<div className="eob-grid-item">
							<div className="category-heading">
								<FaExclamationCircle />
								<span>Claim Summary</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Total Amount Covered:</span>
								<span className="field-value">
									$ <Skeleton animation="wave" width={200} height={40} />
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Paid by Other Insurance Company:</span>
								<span className="field-value">
									$ <Skeleton animation="wave" width={200} height={40} />
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Total Paid By Plan:</span>
								<span className="field-value">
									$ <Skeleton animation="wave" width={200} height={40} />
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">{"Employee's Responsibility:"}</span>
								<span className="field-value">
									{" "}
									$ <Skeleton animation="wave" width={200} height={40} />
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Deductable Remaining:</span>
								<span className="field-value">
									{" "}
									$ <Skeleton animation="wave" width={200} height={40} />
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Out Of Pocket Remaining Plan:</span>
								<span className="field-value">
									{" "}
									$ <Skeleton animation="wave" width={200} height={40} />
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Annual Accumulation:</span>
								<span className="field-value">
									{" "}
									$ <Skeleton animation="wave" width={200} height={40} />
								</span>
							</div>
						</div>
					</div>
					{/* ============================= CLAIM DETAILS ====================================*/}
					<div className="subheading-main">
						<BiSolidReport />
						Claim Details
					</div>
					<Skeleton animation="wave" width={950} height={400} />

					{/* <ExplanationOfBenefitsGrid
						eligibleMembers={eobDetails?.claimDetailsInstance}
						claimTotal={eobDetails?.claimTotalInstance}
					/> */}

					{/* ============================= REMARKS ====================================*/}
					<div className="subheading-main">
						<TbFileReport />
						Remarks
					</div>
					<Skeleton animation="wave" width={200} height={40} />
					<Skeleton animation="wave" width={950} height={200} />
				</div>
			</div>
		</>
	);
};

export default ExplanationOfBenefitsSkeleton;
