import React from "react";
import { IRemarks } from "../../../../../../@types/components/remarks.types";

const RemarksCard = ({ remark, index }: { remark: IRemarks; index: number }) => {
	return (
		<div className="remarks_card">
			<p style={{ fontWeight: "bold" }}>CHARGE LINE {index + 1}</p>
			<div className="inline-flex">
				{remark.EXPLANATION_CODE_1 === 0 ? null : (
					<div className="inline-flex">
						<p style={{ marginRight: 10 }}>{remark.EXPLANATION_CODE_1}</p>
						{/* <p>{remark.DESCR1}</p> */}
					</div>
				)}
				{remark.EXPLANATION_CODE_2 === 0 ? null : (
					<div className="inline-flex">
						<p style={{ marginRight: 10 }}>{remark.EXPLANATION_CODE_2}</p>
						{/* <p>{remark.DESCR2}</p> */}
					</div>
				)}
				{remark.EXPLANATION_CODE_3 === 0 ? null : (
					<div className="inline-flex">
						<p style={{ marginRight: 10 }}>{remark.EXPLANATION_CODE_3}</p>
						{/* <p>{remark.DESCR3}</p> */}
					</div>
				)}
			</div>
		</div>
	);
};

export default RemarksCard;
