import { StatusCodes } from "http-status-codes";
import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { AUTHORIZATION } from "../../../constants/api/auth";

const { get } = request;
const { Authorization, Bearer } = AUTHORIZATION;

export const getClaimStatusDetails = async (
	SSN: string,
	date_of_birth: string,
	tax_id: string,
	date_of_service: string
) => {
	try {
		const endpoint = `claims/claim-status?SSN=${SSN}&date_of_birth=${date_of_birth}&tax_id=${tax_id}&date_of_service=${date_of_service}`;
		// const token = localStorage.getItem("@jwt");
		const response = await get(endpoint, {
			...headers
			// [Authorization]: `${Bearer} ${token}`
		});

		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { memberResult, claimResult }
				} = response;
				return { memberResult, claimResult };
			}
		}
		throw new Error();
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;
			// console.log("=====>SSN", SSN);
			// alert(`DOB is ${date_of_birth} and ssn is ${SSN}`);
			alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;
			alert(message);
		} else {
			throw error;
		}
	}
};
