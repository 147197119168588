import { lazy } from "react";
import FooterV2 from "./footer/FooterV2";

export { default as Navbar } from "./navbar/Navbar";
export { default as Footer } from "./footer/Footer";
export { default as FooterV2 } from "./footer/FooterV2";
export { default as FAQ } from "./frequentlyAskedQuestions/FAQ";
export { default as Article } from "./article/Article";
export { default as Hero } from "./hero/Hero";
export { default as CustomSidebar } from "./mobile/sidebar/Sidebar";
export { default as PlanDesignGroups } from "./mobile/planDesignGroups/PlanDesignGroups";
export { default as CustomContactFrom } from "./customContactForm/CustomContactForm";
export { default as CustomAccordion } from "./customAccordion/CustomAccordion";

export const LazyCustomSidebar = lazy(() => import("./mobile/sidebar/Sidebar"));

export { default as LogoLoader } from "./logoLoader/LogoLoader";
export { default as ExplanationOfBenefitsSkeleton } from "./EOBSkeleton/ExplanationOfBenefitsSkeleton";
