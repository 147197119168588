import React from "react";
import { Tooltip } from "@mui/material";
import { MdPushPin } from "react-icons/md";
import ProviderDetailsRecommendedCard from "./providerDetailsRecommendedCard/ProviderDetailsRecommendedCard";
import { LocationPointerGif, ZIPNotFound } from "../../../../assets";
import { ProviderListProps } from "../../../../@types/components/providerSearch.type";
import ProviderDetailsOtherCard from "./providerDetailsOtherCard/ProviderDetailsOtherCard";

import "./providerList.css";

const ProviderList: React.FC<ProviderListProps> = ({ providersResponse, handleOpenProviderMapViewer }) => {
	const { entiraClinicResult, otherProvidersResult, pagination } = providersResponse;

	if (!providersResponse) return <></>;

	return (
		<div className="provider-list" id="provider-list">
			<div className="provider-list-header">
				<div className="provider-list-title">Provider List</div>
				<Tooltip title="Show Providers Location">
					<img
						src={LocationPointerGif}
						onClick={handleOpenProviderMapViewer}
						width={"60px"}
						height={"60px"}
					/>
				</Tooltip>
			</div>
			<div className="provider-list-body-container">
				<div className="listings-container">
					<div className="featured-listings">
						<div className="featured-listings-header">
							<MdPushPin className="featured-icon" />
							<div className="featured-title">Featured</div>
						</div>
						<div className="featured-listings-cards">
							<ProviderDetailsRecommendedCard entiraClinicResult={entiraClinicResult} />
						</div>
					</div>
					<div className="normal-listings">
						<div className="normal-listings-header">
							<MdPushPin className="normal-icon" />
							<div className="normal-title">Other Providers</div>
						</div>
						{providersResponse.otherProvidersResult.length === 0 ? (
							<div className="empty-other-providers-container">
								<img className="zip-not-found-image" src={ZIPNotFound} />
								<div className="not-found-text">
									<h3>No Other Providers found with this ZIP...</h3>
								</div>
							</div>
						) : (
							<div className="normal-listings-cards">
								<ProviderDetailsOtherCard
									otherProvidersResult={otherProvidersResult}
									pagination={pagination}
								/>
							</div>
						)}
						<div className="normal-listings-cards">{/* <ProviderDetailsOtherCard /> */}</div>
						<div className="normal-listings-cards">{/* <ProviderDetailsOtherCard /> */}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProviderList;
