import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { Backdrop, Theme, createStyles, makeStyles } from "@mui/material";
import { MagnifyingGlass } from "react-loader-spinner";
import { marker } from "leaflet";
import { zipToCoordinateConverter } from "../../../../utils/commonFunctions/zipToCoordinateConverter";
import CustomMap from "./customMapView/CustomMap";

export type ProvidersMapSectionProps = {
	refresh: boolean;
	setRefresh: Dispatch<SetStateAction<boolean>>;
};

export type ProvidersMarkers = MapMarkerProvider[];

type MapMarkerProvider = {
	positionProps: number[];
	nameProps: string;
	miles: string;
};

export type ProvidersCustomMapView = {
	markers: Markers[];
	mapType: string;
	index: number;
};
type Markers = {
	positionProps: number[];
	nameProps: string;
	miles: string;
};

const ProvidersLocationMap: React.FC<{ apiResponse: any; userZipCode: any }> = ({ apiResponse, userZipCode }) => {
	const { otherProvidersResultForZip, entiraClinicResult } = apiResponse;

	// const [loadingState, setLoadingState] = useState<boolean>(true);
	// const backdropClasses = backdropStyles();
	const [providersMarkers, setProvidersMarkers] = useState<ProvidersMarkers[]>([]);

	const { entiraLatitude, entiraLongitude } = apiResponse;
	const [positionProps, setPositionProps] = useState<number[]>([]);

	useEffect(() => {
		const storedMapMarkerParent: ProvidersMarkers = [];
		const _storedAllProvidersLocationCoordinates: any[] = [];
		if (entiraClinicResult) {
			const _storedSingleLocationCoordinates: any[] = [];
			const processedLocationCoordinates = zipToCoordinateConverter("75904");
			_storedSingleLocationCoordinates.push(processedLocationCoordinates[0]);
			_storedSingleLocationCoordinates.push(processedLocationCoordinates[1]);

			_storedAllProvidersLocationCoordinates.push({
				positionProps: _storedSingleLocationCoordinates,
				nameProps: "Entira clinic",
				miles: ""
			});
		}

		if (otherProvidersResultForZip) {
			otherProvidersResultForZip.map((provider: any) => {
				console.log("provider", provider);
				const { facility_name, latitude, longitude, miles } = provider;
				const _storedSingleLocationCoordinates: any[] = [];
				_storedSingleLocationCoordinates.push(latitude);
				_storedSingleLocationCoordinates.push(longitude);
				_storedAllProvidersLocationCoordinates.push({
					positionProps: _storedSingleLocationCoordinates,
					nameProps: `${facility_name}`,
					miles: `${miles}`
				});
			});
			setProvidersMarkers(_storedAllProvidersLocationCoordinates);
		}
		console.log("_storedAllProvidersLocationCoordinates", _storedAllProvidersLocationCoordinates);

		console.log("providersMarkers", providersMarkers);
	}, [otherProvidersResultForZip, entiraClinicResult]);

	return (
		<div className="map-container">
			{providersMarkers.length > 0 && (
				<div className="map-container-section-information-container">
					{/* <CustomMapView mapType={"ZIP WISE"} markers={providersMarkers as unknown as Markers[]} index={0} /> */}
					<CustomMap markers={providersMarkers as unknown as Markers[]} userZipCode={userZipCode} />
				</div>
			)}
		</div>
	);
};

export default ProvidersLocationMap;
