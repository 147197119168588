import prefixCharacterAddition from "./prefixCharacterAddition";

const dateConverterUS = (_date: string): string => {
	const parsedDate: number = Date.parse(_date);
	const date: Date = new Date(parsedDate);
	const strDate: string = date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear();
	return strDate;
};

export default dateConverterUS;

export const getCurrentDate = (): string =>
	new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate();

export const getFirstOfNextMonth = (): string => {
	const date = new Date();
	const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
	console.log("newdate", newDate);
	const formattedDate = newDate.getFullYear() + "-" + newDate.getMonth() + "-" + newDate.getDay();
	return formattedDate;
};

export const compareInvalidDates = (_date1: string, _date2: string): boolean => {
	const date1 = new Date(_date1);
	const date2 = new Date(_date2);

	const firstDate = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();
	const secondDate = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();

	if (new Date(secondDate).getTime() < new Date(firstDate).getTime()) return true;

	return false;
};

export const manualDateComparator = (_date1: string, _date2: string): number => {
	const date1 = new Date(_date1);
	const date2 = new Date(_date2);

	const firstDate = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate();
	const secondDate = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();

	if (new Date(firstDate).getTime() > new Date(secondDate).getTime()) return 1;
	else if (new Date(firstDate).getTime() < new Date(secondDate).getTime()) return -1;
	else return 0;
};

// Currently Used Date Function - Start
export const dateTimeZoneConverter = (_date: string): Date | string => {
	let timeZoneFormattedDate: Date;
	const date = new Date(_date);

	const userTimeZoneOffset = date.getTimezoneOffset() * 60000; // Converting the TimezoneOffset() to milliseconds

	if (userTimeZoneOffset >= 0) timeZoneFormattedDate = new Date(date.getTime() + userTimeZoneOffset);
	else timeZoneFormattedDate = new Date(date.getTime() - userTimeZoneOffset);

	return UIFormattedDate(timeZoneFormattedDate);
};

export const UIFormattedDate = (date: Date): string => {
	const formattedDate =
		prefixCharacterAddition(date.getMonth() + 1) +
		"-" +
		prefixCharacterAddition(date.getDate()) +
		"-" +
		date.getFullYear();

	return formattedDate;
};

export const dateTimeZoneDOBConverter = (_date: string): Date | string => {
	let timeZoneFormattedDate: Date;
	const date = new Date(_date);

	const userTimeZoneOffset = date.getTimezoneOffset() * 60000; // Converting the TimezoneOffset() to milliseconds

	if (userTimeZoneOffset >= 0) timeZoneFormattedDate = new Date(date.getTime() + userTimeZoneOffset);
	else timeZoneFormattedDate = new Date(date.getTime() - userTimeZoneOffset);

	return UIFormattedDOBDate(timeZoneFormattedDate);
};

export const UIFormattedDOBDate = (date: Date): string => {
	const formattedDate =
		prefixCharacterAddition(date.getMonth() + 1) +
		"/" +
		prefixCharacterAddition(date.getDate()) +
		"/" +
		date.getFullYear();

	return formattedDate;
};

// This function is to set the material ui date to USA Time Zone
export const dateTimeZoneMaterialUIConverter = (_date: string): Date | string => {
	let timeZoneFormattedDate: Date;
	const date = new Date(_date);

	const userTimeZoneOffset = date.getTimezoneOffset() * 60000; // Converting the TimezoneOffset() to milliseconds

	if (userTimeZoneOffset >= 0) timeZoneFormattedDate = new Date(date.getTime() + userTimeZoneOffset);
	else timeZoneFormattedDate = new Date(date.getTime() - userTimeZoneOffset);

	return materialUIFormattedDate(timeZoneFormattedDate);
};

export const materialUIFormattedDate = (date: Date): string => {
	const formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

	return formattedDate;
};
// Currently Used Date Function - End

export const dateConverterToUSFormat = (_date: string): string => {
	const parsedDate: number = Date.parse(_date);
	const date: Date = new Date(parsedDate);

	const formattedDate =
		prefixCharacterAddition(date.getMonth() + 1) +
		"-" +
		prefixCharacterAddition(date.getDate()) +
		"-" +
		date.getFullYear();

	return formattedDate;
};

export const isTermDateMoreThanEightDays = (date: Date | string): boolean => {
	//   days hours min  sec  mili-sec
	// Here logic is eight days after the term, but ninth day is taken as getTime() value comparison gives different result for DB date and Mui Date
	const ninthDaysInMs = 9 * 24 * 60 * 60 * 1000; //Eight Days in Mili Seconds
	const givenDate = new Date(date).getTime();
	const timeStampEightDaysAgo = new Date().getTime() - ninthDaysInMs;

	if (givenDate < timeStampEightDaysAgo) return true;

	return false;
};

export const timeConverter = (_time: string): string => {
	const parsedTime: number = Date.parse(_time);
	const time: Date = new Date(parsedTime);

	const hours = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
	const minutes = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
	const seconds = time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();

	const formatedTime = hours + ":" + minutes + ":" + seconds;

	return formatedTime;
};

// This function directly parse the mongoDB date without new Date()
export const mongoDBDateParserToUSAFormat = (date: string): string => {
	const splitDate = date.split("-");
	const year = splitDate[0];
	let month = splitDate[1];
	let day = splitDate[2].split("T")[0];

	if (Number(month) < 10 && !month.startsWith("0")) month = "0" + month;

	if (Number(day) < 10 && !day.startsWith("0")) day = "0" + day;

	const revisedDate = month + "-" + day + "-" + year;
	return revisedDate;
};
export const mongoDBDateParserToUSAFormatV2 = (date: string): string => {
	const splitDate = date.split("-");
	const year = splitDate[0];
	let month = splitDate[1];
	let day = splitDate[2].split("T")[0];

	if (Number(month) < 10 && !month.startsWith("0")) month = "0" + month;

	if (Number(day) < 10 && !day.startsWith("0")) day = "0" + day;

	const revisedDate = year + "-" + month + "-" + day;
	return revisedDate;
};

export const mongoDBDateParserToUSAFormat_v2 = (date: string): string => {
	const splitDate = date.split("-");
	const year = splitDate[0];
	let month = splitDate[1];
	let day = splitDate[2].split("T")[0];

	if (Number(month) < 10 && !month.startsWith("0")) month = "0" + month;

	if (Number(day) < 10 && !day.startsWith("0")) day = "0" + day;

	const revisedDate = month + "-" + day + "-" + year;
	return revisedDate;
	// return UIFormattedDOBDate(new Date(revisedDate));
};

export const formatUSADateService = (date: string): string => {
	const splitDate = date.split("-");
	const year = splitDate[0];
	let month = splitDate[1];
	let day = splitDate[2];

	if (Number(month) < 10 && !month.startsWith("0")) month = "0" + month;

	if (Number(day) < 10 && !day.startsWith("0")) day = "0" + day;

	const revisedDate = month + "-" + day + "-" + year;
	return revisedDate;
};

export const getCurrentDateForReportGeneration = (): string => {
	const date: string | Date = new Date();
	const formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
	return formatUSADateService(formattedDate);
};

export const isLeapYear = (year: number): boolean => {
	if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) return true;
	return false;
};

export const monthStartDateGenerator = (month: string, enrollmentYear: number): string => {
	let startDate: Date | undefined;
	const currentYear = new Date(enrollmentYear, 0).getFullYear();
	if (month === "January") {
		startDate = new Date(`${currentYear}-01-01`);
	} else if (month === "February") {
		startDate = new Date(`${currentYear}-02-01`);
	} else if (month === "March") {
		startDate = new Date(`${currentYear}-03-01`);
	} else if (month === "April") {
		startDate = new Date(`${currentYear}-04-01`);
	} else if (month === "May") {
		startDate = new Date(`${currentYear}-05-01`);
	} else if (month === "June") {
		startDate = new Date(`${currentYear}-06-01`);
	} else if (month === "July") {
		startDate = new Date(`${currentYear}-07-01`);
	} else if (month === "August") {
		startDate = new Date(`${currentYear}-08-01`);
	} else if (month === "September") {
		startDate = new Date(`${currentYear}-09-01`);
	} else if (month === "October") {
		startDate = new Date(`${currentYear}-10-01`);
	} else if (month === "November") {
		startDate = new Date(`${currentYear}-11-01`);
	} else if (month === "December") {
		startDate = new Date(`${currentYear}-12-01`);
	}
	const formattedDate =
		startDate !== undefined
			? startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate()
			: "";

	return formattedDate;
};

export const monthEndDateGenerator = (month: string, enrollmentYear: number): string => {
	let endDate: Date | undefined;
	const currentYear = new Date(enrollmentYear, 0).getFullYear();
	if (month === "January") {
		endDate = new Date(`${currentYear}-01-31`);
	} else if (month === "February") {
		const lastDayOfFeb = isLeapYear(currentYear) ? 29 : 28;
		endDate = new Date(`${currentYear}-02-${lastDayOfFeb}`);
	} else if (month === "March") {
		endDate = new Date(`${currentYear}-03-31`);
	} else if (month === "April") {
		endDate = new Date(`${currentYear}-04-30`);
	} else if (month === "May") {
		endDate = new Date(`${currentYear}-05-31`);
	} else if (month === "June") {
		endDate = new Date(`${currentYear}-06-30`);
	} else if (month === "July") {
		endDate = new Date(`${currentYear}-07-31`);
	} else if (month === "August") {
		endDate = new Date(`${currentYear}-08-31`);
	} else if (month === "September") {
		endDate = new Date(`${currentYear}-09-30`);
	} else if (month === "October") {
		endDate = new Date(`${currentYear}-10-31`);
	} else if (month === "November") {
		endDate = new Date(`${currentYear}-11-30`);
	} else if (month === "December") {
		endDate = new Date(`${currentYear}-12-31`);
	}
	const formattedDate =
		endDate !== undefined ? endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate() : "";

	return formattedDate;
};

export const integrationDateFormatter = (date: string): string => {
	const dateParser = new Date(date as unknown as Date);

	return dateParser.getFullYear() + "-" + (dateParser.getMonth() + 1) + "-" + dateParser.getDate();
};

export const monthDayFormatterForWLTQuery = (date: string): string => {
	const parsedDate = mongoDBDateParserToUSAFormatV2(date);
	const formattedDate = new Date(parsedDate);
	let year = formattedDate.getFullYear();
	let month = formattedDate.getMonth() + 1;
	let day = formattedDate.getDate();

	// alert(`Month: ${parsedDate}`);

	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	if (timeZone.includes("America")) {
		formattedDate.setDate(formattedDate.getDate() + 1);
		year = formattedDate.getFullYear();
		month = formattedDate.getMonth() + 1;
		day = formattedDate.getDate();
		// if (Number(month) > 10 && Number(day) > 10) {
		// 	formattedDate.setDate(formattedDate.getDate() + 1);
		// 	year = formattedDate.getFullYear();
		// 	month = formattedDate.getMonth() + 1;
		// 	day = formattedDate.getDate();
		// }
	}

	const revisedDate = month + "-" + day + "-" + year;
	return revisedDate;
};

export const monthDayFormatterForWLTQueryForAutoSearch = (date: string): string => {
	const formattedDate = new Date(date);
	let year = formattedDate.getFullYear();
	let month = formattedDate.getMonth() + 1;
	let day = formattedDate.getDate();

	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	console.log("TimeZone Auto Search: ", timeZone);

	if (timeZone.includes("America")) {
		formattedDate.setDate(formattedDate.getDate() + 1);
		year = formattedDate.getFullYear();
		month = formattedDate.getMonth() + 1;
		day = formattedDate.getDate();
	}

	const revisedDate = month + "-" + day + "-" + year;
	return revisedDate;
};
