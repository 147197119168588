export const ZIPFormat = (input: string): string => {
	if (input.length !== 9 || !/^\d{9}$/.test(input)) {
		throw new Error("Input should be a string of 9 digits");
	}

	const firstFive = input.slice(0, 5);
	const lastFour = input.slice(5);

	return `${firstFive}-${lastFour}`;
};
