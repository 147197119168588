import { StatusCodes } from "http-status-codes";
import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { AUTHORIZATION } from "../../../constants/api/auth";

const { get } = request;
const { Authorization, Bearer } = AUTHORIZATION;

export const getEligibilityCheckDetails = async (SSN: string, date_of_birth: string) => {
	try {
		const endpoint = `eligibility-check?SSN=${SSN}&date_of_birth=${date_of_birth}`;
		const token = localStorage.getItem("@jwt");
		const response = await get(endpoint, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});

		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result, employeeResult }
				} = response;
				return { result, employeeResult };
			}
		}
		throw new Error();
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;
			// console.log("=====>SSN", SSN);
			alert(`DOB is ${date_of_birth} and ssn is ${SSN}`);
			// alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;
			alert(message);
		} else {
			throw error;
		}
	}
};
