import React, { useState, useCallback, ChangeEvent, FormEvent } from "react";
import { AiOutlineTeam, AiOutlineMail, AiOutlineIdcard, AiFillMail } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaFax } from "react-icons/fa";
import { Card, CardActions, CardContent, CardHeader, Stack } from "@mui/material";

import { ContactConstant } from "../../../@types/contact.types";
import { NexcaliberMembers } from "../../../@types/nexcaliberMembers.types";
import { FormData, FormErrors } from "../../../@types/contactFormDataError.types";

import { CONTACTS } from "../../../constants/contacts";
import { NEXCALIBER_MEMBER } from "../../../constants/nexcaliberMember";
import CustomContactForm from "../../shared/customContactForm/CustomContactForm";

import { api } from "../../../utils/api";

import "./contact.css";

const Contact: React.FC = () => {
	const [formData, setFormData] = useState<FormData>({
		name: "",
		organization: "",
		designation: "",
		email: "",
		phone_number: "",
		identification: "",
		query: ""
	});
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [isSubmit, setIsSubmit] = useState(false);

	const validate = (values: FormData) => {
		const errors: FormErrors = {};

		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
		const phoneRegex = /^[0-9]{10}$/;
		if (!values.name) {
			errors.name = "Name is required!";
		}
		if (!values.organization) {
			errors.organization = "Organization is required!";
		}
		if (!values.email) {
			errors.email = "Email is required!";
		} else if (!regex.test(values.email)) {
			errors.email = "This is not a valid email format!";
		}
		if (!values.phone_number) {
			errors.phone_number = "Contact is required!";
		} else if (!phoneRegex.test(values.phone_number)) {
			errors.phone_number = "Please enter a valid 10-digit phone number.";
		}
		if (!values.identification) {
			errors.identification = "Choose an option!";
		}
		if (!values.query) {
			errors.query = "Enter your query!";
		}
		return errors;
	};

	const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleDropdownChange = (event: ChangeEvent<HTMLSelectElement>) => {
		setFormData({
			...formData,
			identification: event.target.value
		});
	};

	const handleSubmit = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			setFormErrors(validate(formData));
			console.log("check ", Object.keys(formErrors));

			try {
				if (Object.keys(formErrors).length === 0) {
					console.log("API Inside");
					console.log("formData", formData);

					const response = await api.contactQueryForm.contactQueryForm(formData);
					console.log("res", response);
					if (response && response.status === 200) {
						console.log("API Response:", response);
						setFormData({
							name: "",
							organization: "",
							designation: "",
							email: "",
							phone_number: "",
							identification: "",
							query: ""
						});
						alert("successfully sent your query");
					} else {
						console.error("Unexpected response status code:", response && response.status);
					}
				}
			} catch (error) {
				console.error("sanglap", error);
			}

			setIsSubmit(true);
		},
		[formData, formErrors]
	);

	return (
		<main className="main-contact-container">
			<section className="main-content">
				<div className="hero" />
				<div className="location-contact-conatainer">
					<div className="location-contact-form">
						<CustomContactForm
							formData={formData}
							onChangeHandler={onChangeHandler}
							handleDropdownChange={handleDropdownChange}
							handleSubmit={handleSubmit}
							formErrors={formErrors}
						/>
					</div>

					<div className="location-contact-details">
						<h3 style={{ margin: 0, fontSize: 30 }}>Contact Details</h3>
						<p className="description-text">
							For more information about cost-effective concepts and simple, workable alternatives to
							employer group benefit programs, call or email us today at the following numbers:
						</p>

						{CONTACTS.map((data: ContactConstant, index: number) => {
							return (
								<div className="contact-details-container" key={index}>
									{data.type === "fax" ? (
										<FaFax className="contact-logo" />
									) : data.type === "mail" ? (
										<AiFillMail className="contact-logo" />
									) : (
										<BiSolidPhoneCall className="contact-logo" />
									)}
									<a className="contact-labels">{data.contact_label}</a>
									<a className="contact-values">{data.contact_value}</a>
								</div>
							);
						})}
					</div>

					<div className="embed-map">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13392.301336364151!2d-96.8249527!3d32.9490204!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c212ffdcd406f%3A0xaaa345b6ef491078!2sNexcaliber!5e0!3m2!1sen!2sin!4v1692881938154!5m2!1sen!2sin"
							style={{
								width: "100%",
								height: "400px",
								border: 0
							}}
							allowFullScreen
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						/>
					</div>
				</div>

				<div className="contact-cont">
					<h3 style={{ margin: 0 }} className="nexcaliber-members-heading">
						Nexcaliber Department Staff Contacts
					</h3>

					<section className="main-member-section-container">
						<div className="nexcaliber-members-grid-container">
							{NEXCALIBER_MEMBER.map((member: NexcaliberMembers, index: number) => {
								return (
									<div key={index}>
										<Card style={{ margin: "0.5rem" }}>
											<CardHeader
												avatar={
													<Stack direction={"row"}>
														<AiOutlineTeam style={{ fontSize: "1rem" }} />
														<h3 style={{ margin: 0, marginLeft: "15px" }}>
															{member.member_name}
														</h3>
													</Stack>
												}
											/>
											<CardContent>
												<h4 style={{ marginTop: 0 }}>{member.member_designation}</h4>
												<Stack direction={"row"}>
													<AiOutlineMail
														style={{
															fontSize: "1.2rem",
															marginTop: "2px",
															marginRight: "10px"
														}}
													/>
													<a
														style={{
															textDecoration: "none",
															color: "#4e4e4e",
															fontWeight: "bold"
														}}
														href={`mailto: ${member.member_email}`}
													>
														{member.member_email}
													</a>
												</Stack>
												<Stack direction={"row"} style={{ marginTop: "10px" }}>
													<AiOutlineIdcard
														style={{
															fontSize: "1.2rem",
															marginTop: "2px",
															marginRight: "10px"
														}}
													/>
													<a
														style={{
															textDecoration: "none",
															color: "#4e4e4e",
															fontWeight: "bold"
														}}
														href={`mailto: ${member.member_email}`}
													>
														Ext {member.member_memberid}
													</a>
												</Stack>
											</CardContent>
											<CardActions></CardActions>
										</Card>
									</div>
								);
							})}
						</div>
					</section>
				</div>
			</section>
		</main>
	);
};

export default Contact;
