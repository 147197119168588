import { getClaimStatusDetails } from "./claimStatus/getClaimStatusDetails";
import { getEOBDetails } from "./claimStatus/getEOBDetails";
import { getEOBDetailsV3 } from "./claimStatus/getEOBDetailsV3";
import { getEOBDetailsWithCheckNumber } from "./claimStatus/getEOBDetailsWithCheckNumber";
import { contactQueryForm } from "./contactQueryForm/contactQueryForm";
import { getEligibilityCheckDetails } from "./eligibilityCheck/getEligibilityCheckDetails";
import { getProvidersDetails } from "./providerSearch/getProviderDetails";

export const api = {
	eligibilityCheck: {
		getEligibilityCheckDetails
	},
	providerSearch: {
		getProvidersDetails
	},
	claimStatus: {
		getClaimStatusDetails,
		getEOBDetails,
		getEOBDetailsV3,
		getEOBDetailsWithCheckNumber
	},
	contactQueryForm: {
		contactQueryForm
	}
};
