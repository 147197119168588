import { StatusCodes } from "http-status-codes";
import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { AUTHORIZATION } from "../../../constants/api/auth";

const { post } = request;
const { Authorization, Bearer } = AUTHORIZATION;

export const getProvidersDetails = async (zipCode: string, inputMiles: string, page: number) => {
	try {
		const endpoint = `providers/get-distance?page=${page}`;
		const token = localStorage.getItem("@jwt");
		const payload = {
			ZIP: zipCode,
			miles: inputMiles
		};
		const response = await post(endpoint, payload, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});

		if (response) {
			console.log("inside the api response", response);
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;

			alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;

			alert(message);
		} else {
			throw error;
		}
	}
};
