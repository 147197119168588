// import React from "react";
import "./summaryOfBenefitsFormPrint.css";
const SummaryOfBenefitsICIFormPrint = () => {
	return (
		<div className="printable-summary-of-benefits-main-container">
			<div className="summary-of-benefits-header">Summary of Benefits for Medical Plan</div>
			<div className="printable-summary-of-benefits-body">
				<div className="printable-left-side">
					<div className="printable-body-container-top">
						<div className="printable-summary-of-benefits-sub-header">
							<div className="printable-sub-header-in-network">In Network</div>
							<div className="printable-sub-header-out-of-network">Out of Network</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Deductible - Ind/Fam</div>
							<div className="second-column">$750/$1,500</div>
							<div className="third-column">
								Out of network providers are included in the same deductible amount.
							</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">
								Out-of-Pocket <br></br>- Ind/Fam
							</div>
							<div className="second-column">$9,100 / $18,200</div>
							<div className="third-column">
								Out of network providers are included in the same deductible amount.
							</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Co-insurance</div>
							<div className="second-column">20% after deductible</div>
							<div className="third-column">20% after deductible</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Office Visit</div>
							<div className="second-column">20% after deductible</div>
							<div className="third-column">20% after deductible</div>
						</div>
					</div>
				</div>
				<div className="printable-right-side">
					<div className="printable-body-container-bottom">
						<div className="printable-summary-of-benefits-sub-header">
							<div className="printable-sub-header-in-network">In Network</div>
							<div className="printable-sub-header-out-of-network">Out of Network</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Hospital Services</div>
							<div className="second-column">20% after deductible</div>
							<div className="third-column">20% after deductible</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Emergency Room Care</div>
							<div className="second-column">20% after deductible</div>
							<div className="third-column">20% after deductible</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Outpatients Services</div>
							<div className="second-column">20% after deductible</div>
							<div className="third-column">20% after deductible</div>
						</div>
						<div className="printable-column-container">
							<div className="first-column">Wellness Benefits</div>
							<div className="second-column">100%</div>
							<div className="third-column">100%</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SummaryOfBenefitsICIFormPrint;
