import React from "react";
import { SERVICE_CARDS } from "../../../../constants/services";

import "./services.css";
import "../../../../index.css";

const Services: React.FC = () => {
	return (
		<section className="customer-section global-padding" id="section1">
			<div className="customer-section-inner">
				<h3 className="subheadings" style={{ textAlign: "center" }}>
					Solutions
				</h3>
				<div className="group-card-container">
					{SERVICE_CARDS.map((data, index) => {
						return (
							<div className="card-container" key={index}>
								<img className="services-icons" src={data.icons} alt="" />
								<span className="card-title">{data.title}</span>
								<span className="card-desc">{data.description}</span>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default Services;
