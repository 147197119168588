import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { Alert, Pagination, Stack } from "@mui/material";
import { HiOutlineSearch } from "react-icons/hi";

import ThemeTextField from "../../shared/textField/ThemeTextField";
import ProviderList from "./providerList/ProviderList";
import { providerSearchDistanceOptions } from "../../../constants/providerSearch";
import { api } from "../../../utils/api";
import { PaginationTypes, ProvidersResult } from "../../../@types/components/providerSearch.type";
import ProviderMapViewerModal from "./providerMapViewerModal/ProviderMapViewerModal";

import "./providerSearch.css";

const ProviderSearch: React.FC = () => {
	const [zipCode, setZipCode] = useState<string>("");
	const [inputMiles, setInputMiles] = useState<string>("");
	const [apiResponse, setApiResponse] = useState<ProvidersResult | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [zipcodeError, setZipcodeError] = useState<string>("");
	const [distanceError, setDistanceError] = useState<string>("");
	const [zipCodeValidation, setZipCodeValidation] = useState<string>("");
	const [hasSearch, setHasSearch] = useState<boolean>(false);
	const [paginations, setPaginations] = useState<PaginationTypes>({
		currentPage: 1,
		pageCount: undefined
	});
	const [openMapViewer, setOpenMapViewer] = useState<boolean>(false);
	const [userZipCode, setUserZipCode] = useState<string>("");

	const getProvidersData = useCallback(async () => {
		try {
			const providersInformationInstance = await api.providerSearch.getProvidersDetails(
				zipCode,
				inputMiles,
				paginations.currentPage ? paginations.currentPage : 1
			);

			if (providersInformationInstance) {
				setApiResponse(providersInformationInstance);
				setPaginations(
					Object.assign({}, paginations, { pageCount: providersInformationInstance.pagination.totalPages })
				);
				setZipcodeError("");
				setDistanceError("");
			} else {
				setApiResponse(null);
				setDistanceError("An error occurred while fetching data.");
			}
		} catch (error: Error | any) {
			if (error.response && error.response.status === 502) {
				setZipcodeError("Please enter a valid Zipcode");
			} else {
				setZipcodeError("An error occurred. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zipCode, inputMiles, paginations.currentPage]);

	const handleSearch = useCallback(async () => {
		if (inputMiles && zipCode) {
			setHasSearch(true);
		}
		if (!inputMiles) {
			setDistanceError("Distance is required.");
		} else {
			setDistanceError("");
		}
		if (!zipCode) {
			setZipCodeValidation("Zipcode is required");
			return;
		} else {
			setUserZipCode(zipCode);
			setZipCodeValidation("");
		}

		setLoading(true);

		await getProvidersData();
	}, [inputMiles, zipCode]);

	const handleProviderSearchInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
			const { name, value } = event.target;
			setHasSearch(false);
			if (name === "zipCode") {
				setZipCode(value);
				setZipCodeValidation("");
			}
			if (name === "distance") {
				setInputMiles(value);
				setDistanceError("");
			}
		},
		[]
	);

	const handlePageChange = useCallback(
		async (event: React.ChangeEvent<unknown>, page: number) => {
			setPaginations(Object.assign({}, paginations, { currentPage: page }));
		},
		[paginations]
	);

	const handleOpenProviderMapViewer = () => {
		setOpenMapViewer(!openMapViewer);
	};

	useEffect(() => {
		if (zipCode !== "" && inputMiles !== "" && hasSearch) {
			getProvidersData();
		}
	}, [getProvidersData, zipCode, inputMiles, hasSearch]);

	return (
		<div className="provider-search">
			<div className="header-container">
				<div className="provider-search-header" id="provider-search-header">
					Search Provider
				</div>
				<Alert
					severity="warning"
					sx={{ fontSize: "1rem", width: "40%", marginTop: "30px" }}
					className="disclaimer-container"
				>
					<strong className="disclaimer">Disclaimer:</strong>The Nexcaliber network on this site includes only
					the <span style={{ fontWeight: "600" }}>DIRECT CONTRACTS</span> we have in place with providers. For
					a full list of providers available to you, please see the network instructions on your
					identification card or contact Nexcaliber at (800) 741-0185 during business hours.
				</Alert>
				<div className="provider-search-container" id="provider-search-container">
					<div className="provider-search-content-area" id="provider-search-content-area">
						<div className="search-box-container">
							<div className="search-box-zip">
								<div className="zip-label">ZIP</div>
								<div className="zip-input">
									<ThemeTextField
										name="zipCode"
										fullWidth
										value={zipCode}
										onChange={handleProviderSearchInputChange}
									/>
									<p
										style={{
											color: "red",
											visibility: zipCodeValidation ? "visible" : "hidden",
											fontSize: "12px",
											height: "8px"
										}}
									>
										{zipCodeValidation}
									</p>
								</div>
							</div>
							<div className="search-box-distance">
								<div className="distance-label">Distance</div>
								<div className="distance-dropdown">
									<select
										name="distance"
										value={inputMiles}
										onChange={handleProviderSearchInputChange}
									>
										<option value="" disabled selected>
											in Miles
										</option>
										{providerSearchDistanceOptions.map((distance, index) => (
											<option value={distance} key={index}>
												{distance} Miles
											</option>
										))}
									</select>
								</div>
								<p
									style={{
										color: "red",
										visibility: distanceError ? "visible" : "hidden",
										fontSize: "12px",
										height: "8px"
									}}
								>
									{distanceError}
								</p>
							</div>

							<button
								onClick={handleSearch}
								style={{
									border: "none",
									height: "40px",
									width: "40px",

									background: "violet",
									borderRadius: "8px",
									textAlign: "center",
									marginBottom: "10px"
								}}
								className="search-button"
							>
								<HiOutlineSearch
									style={{ color: "white", width: "1.5rem", height: "2rem", textAlign: "center" }}
								/>
							</button>
						</div>
					</div>
					{zipcodeError && (
						<div
							style={{
								backgroundColor: "#ffebee",
								color: "#b71c1c",
								border: "1px solid #b71c1c",
								padding: "10px",
								borderRadius: "5px",
								marginTop: "15px",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center"
							}}
						>
							<h3 style={{ margin: "0" }}>{zipcodeError}</h3>
							<button
								style={{
									border: "none",
									backgroundColor: "transparent",
									cursor: "pointer"
								}}
								onClick={() => setZipcodeError("")}
							>
								&times;
							</button>
						</div>
					)}
				</div>
			</div>
			<ProviderMapViewerModal
				handleOpenProviderMapViewer={handleOpenProviderMapViewer}
				openMapViewer={openMapViewer}
				apiResponse={apiResponse}
				userZipCode={userZipCode}
			/>

			<div className="provider-list-area-main-container">
				{loading && (
					<div className="loader">
						<div className="spinner"></div>
					</div>
				)}
				{apiResponse && !loading && (
					<>
						<ProviderList
							providersResponse={apiResponse}
							handleOpenProviderMapViewer={handleOpenProviderMapViewer}
						/>
						{paginations.pageCount === 0 || paginations.pageCount === undefined ? null : (
							<div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
								<Stack spacing={2}>
									<Pagination
										count={paginations.pageCount}
										color="secondary"
										onChange={handlePageChange}
									/>
								</Stack>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ProviderSearch;
