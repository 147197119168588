import { BillNegotiationLogo, ConsultaionsLogo, EvaluationsLogo, PlanDesignLogo, RecommendationsLogo } from "../assets";

export const PLANDESIGNGROUPS = [
	{
		label: "Consultaions",
		description: "",
		icons: ConsultaionsLogo
	},
	{
		label: "Evaluations",
		description: "",
		icons: EvaluationsLogo
	},
	{
		label: "Recommendations",
		description: "",
		icons: RecommendationsLogo
	},
	{
		label: "Plan Design",
		description: "",
		icons: PlanDesignLogo
	},
	{
		label: "Bill Negotiation",
		description: "",
		icons: BillNegotiationLogo
	}
];
