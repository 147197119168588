import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home, Contact, ProviderSearch, ClaimStatus, ExplanationOfBenefits } from "./components/pages";
import { FooterV2, Navbar } from "./components/shared";
import EligibilityCheck from "./components/pages/eligibilityCheck/EligibilityCheck";
import ScrollToTop from "./utils/commonFunctions/scrollToTop";

import "./global/globalStyles.css";

const App = () => {
	return (
		<div className="App">
			<BrowserRouter>
				<ScrollToTop />
				<Navbar />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/provider-search" element={<ProviderSearch />} />
					<Route path="/eligibility-check" element={<EligibilityCheck />} />
					<Route path="/claim-status" element={<ClaimStatus />} />
					<Route path="/claim-status/EOB" element={<ExplanationOfBenefits />} />
				</Routes>
				<FooterV2 />
			</BrowserRouter>
		</div>
		//yo
	);
};

export default App;
