import { StatusCodes } from "http-status-codes";
import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { AUTHORIZATION } from "../../../constants/api/auth";

const { get } = request;
const { Authorization, Bearer } = AUTHORIZATION;

export const getEOBDetailsV3 = async (claim_number: string, claim_prefix: string, claim_suffix: string) => {
	try {
		const endpoint = `claims/eob-details-v3?claim_number=${claim_number}&claim_prefix=${claim_prefix}&claim_suffix=${claim_suffix}`;
		const token = localStorage.getItem("@jwt");
		const response = await get(endpoint, {
			...headers,
			[Authorization]: `${Bearer} ${token}`
		});

		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.get.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;
			// console.log("=====>SSN", SSN);
			// alert(`DOB is ${date_of_birth} and ssn is ${SSN}`);
			alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;
			alert(message);
		} else {
			throw error;
		}
	}
};
