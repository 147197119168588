import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { CUSTOM_SIDEBAR_ITEMS } from "../../../../constants/customSidebarItems";
import { SidebarList, SidebarProps } from "../../../../@types/sidebar.types";

const CustomSidebar: React.FC<SidebarProps> = ({ open, onClose }) => {
	return (
		<Drawer
			classes={{
				paper: "custom-drawer-paper"
			}}
			anchor="left"
			open={open}
			onClose={onClose}
		>
			<List>
				{CUSTOM_SIDEBAR_ITEMS.map((element: SidebarList, index: number) => {
					return (
						<Link to={element.redirect} key={index} style={{ textDecoration: "none", fontSize: 20 }}>
							<ListItem onClick={onClose} className="custom-list-items-container">
								<ListItemIcon>{<element.icon />}</ListItemIcon>
								<ListItemText style={{ color: "black" }} primary={element.listValue} />
							</ListItem>
						</Link>
					);
				})}
			</List>
		</Drawer>
	);
};

export default CustomSidebar;
