import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { Paper } from "@mui/material";
import { MdLocationOn } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";
import { IProviders } from "../../../../../@types/providers.types";
import { ProviderDetailsOtherCardProps } from "../../../../../@types/components/providerSearch.type";

import "./providerDetailsOtherCard.css";

const ProviderDetailsOtherCard: React.FC<ProviderDetailsOtherCardProps> = ({ otherProvidersResult, errorMessage }) => {
	if (errorMessage) return <div className="error-message">{errorMessage}</div>;

	if (!otherProvidersResult) return <></>;

	return (
		<div className="other-provider-details-container" id="other-provider-details-container">
			{otherProvidersResult.map(
				(
					{
						first_name,
						last_name,
						facility_name,
						address,
						city,
						state,
						ZIP,
						phone_number,
						miles
					}: IProviders,
					index: number
				) => {
					const alternateFacilityName = first_name + " " + last_name;
					return (
						<Paper key={index} elevation={3} sx={{ position: "relative", marginTop: "14px" }}>
							<div className="other-provider-details-card">
								<div className="provider-details-top">
									<div className="provider-name">
										{facility_name ? facility_name : alternateFacilityName}
									</div>
								</div>
								<div className="provider-details-bottom">
									<div className="provider-address">
										<MdLocationOn className="address-icon" />

										<div className="address-content">
											{address}, {city}, {state},<br />
											{ZIP}
										</div>
									</div>
									<div className="provider-number">
										<FaPhoneAlt className="number-icon" />

										<div className="number-content">+1{phone_number}</div>
									</div>

									{miles === "0.00" ? (
										<h3 className="provider-near-by">
											<FaMapMarkedAlt className="map-marker-icon" />
											Within your area
										</h3>
									) : (
										<div className="provider-distance">
											Distance: <span className="provider-distance-value">{miles}</span> Miles
										</div>
									)}
								</div>
							</div>
						</Paper>
					);
				}
			)}
		</div>
	);
};

export default ProviderDetailsOtherCard;
