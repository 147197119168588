import React from "react";

import { Paper } from "@mui/material";
import { MdLocationOn } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaAngleDoubleLeft } from "react-icons/fa";

import { ProviderDetailsRecommendedCardProps } from "../../../../../@types/components/providerSearch.type";
import { EntiraLufkin, TeleconnectLogo } from "../../../../../assets";

import "./providerDetailsRecommendedCard.css";

const ProviderDetailsRecommendedCard: React.FC<ProviderDetailsRecommendedCardProps> = ({ entiraClinicResult }) => {
	if (!entiraClinicResult) {
		return null;
	}

	return (
		<Paper elevation={3} sx={{ position: "relative" }}>
			<div className="sponsored-ribbon-wrapper">
				<div className="glow">&nbsp;</div>
				<span className="ribbon-text">Recommended</span>
				<div className="ribbon-text-support"></div>
			</div>
			<div className="entira-provider-details-card">
				{/* <div className="card-glow">&nbsp;</div> */}
				<div className="provider-details-top">
					<div className="provider-name">
						{/* <div className="glow">&nbsp;</div> */}
						<img className="provider-name-image" src={EntiraLufkin} alt="Entira" />
						<img className="provider-name-image-teleconnect" src={TeleconnectLogo} alt="Teleconnect" />

						{/* <span className="provider-name-text">Clinic</span> */}
					</div>
				</div>
				<div className="provider-details-bottom">
					<div className="provider-address">
						<MdLocationOn className="address-icon" />

						<div className="address-content">1807 W. Frank, Suite 100, Lufkin, TX 75904</div>
					</div>
					<div className="provider-number">
						<FaPhoneAlt className="number-icon" />
						<div className="number-content">+19366348311</div>
					</div>

					{entiraClinicResult.length > 50 ? (
						<div className="provider-distance">
							Distance: <span className="provider-distance-value">{entiraClinicResult}</span> Miles
						</div>
					) : (
						<>
							<div className="icon-container">
								<span className="horizontalArrow side-by-side">
									<FaAngleDoubleLeft className="horizontalArrowIcon" />
								</span>
								<span>
									<h3>
										Call for an appointment today <FaPhoneAlt className="number-icon" />
									</h3>
								</span>
							</div>
						</>
					)}
				</div>
				<div className="provider-details-website">
					<div className="provider-website">
						Visit:{" "}
						<a href="https://entiraclinic.com/" target="_blank" rel="noreferrer">
							Entira Clinic
						</a>
					</div>
					<div className="arrow">
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</Paper>
	);
};

export default ProviderDetailsRecommendedCard;
