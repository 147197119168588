/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import Cursor from "./Cursor";

interface TypewriterProps {
	text: string;
	delay?: number;
}

const TypewriterEffect: React.FC<TypewriterProps> = ({ text, delay = 100 }) => {
	const [visibleText, setVisibleText] = useState("");
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isBackspacing, setIsBackspacing] = useState(false);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (visibleText.length === text.length) {
				setTimeout(() => null, 2000);
			}
			if (isBackspacing) {
				setVisibleText(prevText => prevText.slice(0, -1));
				if (visibleText.length === 0) {
					setIsBackspacing(false);
					setCurrentIndex(0);
				}
			} else if (currentIndex < text.length) {
				setVisibleText(prevText => prevText + text[currentIndex]);
				setCurrentIndex(prevIndex => prevIndex + 1);
			} else {
				setIsBackspacing(true);
			}
		}, delay);

		return () => clearTimeout(timeoutId);
	}, [currentIndex, text, delay, isBackspacing, visibleText]);

	return (
		<span>
			{visibleText}
			<Cursor isVisible={isBackspacing} />
		</span>
	);
};

export default TypewriterEffect;
