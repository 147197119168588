import React from "react";

import "../../../index.css";

const _image =
	"https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA";

import "./article.css";

const Article: React.FC = () => {
	return (
		<article className="article-main-container global-padding">
			<div className="article-container">
				<img className="article-image" src={_image} alt="" />
				<div className="article-textual">
					<span className="title">
						Self-Funding Employer Medical Benefits with a Roundtable of Experts in the New Age of
						Healthcare. <span style={{ color: "#9300FF" }}>The BEST Route for Managing Risk.</span>
					</span>
					<span className="description">
						<ul style={{ listStyle: "inherit" }}>
							<li>
								Employers have been wielding successful adaptive skills with the counsel and guidance of
								Nexcaliber management for four decades. Through managed care, the initiation of
								self-funding and ERISA compliance, consumer cost-shifting, and now the ACA
							</li>
							<li style={{ marginTop: "2rem" }}>
								Nexcaliber has been working through the prevailing circumstances of insurance and
								reinsurance carriers, brokers, medical providers, (plan design, repricing/bill
								negotiation and claims processing for employers), and of course the affects of
								government on all of these participants. Partner with us to allow you to operate with
								freedom.
							</li>
						</ul>
					</span>
				</div>
			</div>
		</article>
	);
};

export default Article;
