// Spinner.js
import React from "react";
import "./logoLoader.css"; // Import your CSS file

const LogoLoader = ({ logoSrc }: any) => {
	return (
		<div className="spinner-container">
			<img src={logoSrc} alt="Brand Logo" className="spinner-logo" />
		</div>
	);
};

export default LogoLoader;
