import React, { useState } from "react";
import { NEXCALIBER_ADVANTAGE_DATA } from "../../../../constants/nexcaliberAdvantageData";
import { CustomAccordion } from "../../../shared";
import { BillNegotiationImg, EntiraClinicImg, PlanDesignImg, ProprietaryNetworkImg } from "../../../../assets";

import "./nexcaliberAdvantage.css";

const NexcaliberAdvantage = () => {
	const [activeTab, setActiveTab] = useState(0);

	// eslint-disable-next-line arrow-parens
	const handleTabClick = (index) => {
		// console.log(index);
		setActiveTab(index);
	};

	const getBackgroundImage = () => {
		const backgroundImages = [ProprietaryNetworkImg, BillNegotiationImg, PlanDesignImg, EntiraClinicImg];
		return backgroundImages[activeTab];
	};

	return (
		<section className="nexcaliber-advantage global-padding">
			<h1 className="nexcailber-advantage-heading">Nexcaliber Advantage</h1>
			<div className="nexcaliber-advantage-container global-padding">
				<div className="nexcaliber-advantage-container-tabs">
					<div className="nexcaliber-advantage-tab-headers">
						<button className={activeTab === 0 ? "active" : ""} onClick={() => handleTabClick(0)}>
							Proprietary Network
						</button>
						<button className={activeTab === 1 ? "active" : ""} onClick={() => handleTabClick(1)}>
							Bill Negotiation
						</button>
						<button className={activeTab === 2 ? "active" : ""} onClick={() => handleTabClick(2)}>
							Plan Design
						</button>
						<button className={activeTab === 3 ? "active" : ""} onClick={() => handleTabClick(3)}>
							Entira Clinic
						</button>
					</div>
					<div className="nexcaliber-advantage-tab-content">
						<div className="nexcaliber-advantage-tab-content-container">
							{NEXCALIBER_ADVANTAGE_DATA.map((data, index) => {
								return (
									<div className="nexcaliber-advantage-tab-content-items" key={index}>
										{activeTab === index && (
											<div
												className="nexcaliber-advantage-tab-content-item"
												style={{ backgroundImage: `url(${getBackgroundImage()})` }}
											>
												<h2 className="tab-content-heading">{data.heading}</h2>
												<div className="tab-content-wrapper">
													<strong className="tab-content-title">{data.title}</strong>
													<p className="tab-content-description">{data.description}</p>
													<strong className="tab-content-postscript">
														{data.postscript}
													</strong>
												</div>
											</div>
										)}
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className="nexcaliber-advantage-container-accordion">
					<CustomAccordion
						activeTab={activeTab}
						handleTabClick={handleTabClick}
						data={NEXCALIBER_ADVANTAGE_DATA}
					/>
				</div>
			</div>
		</section>
	);
};

export default NexcaliberAdvantage;
