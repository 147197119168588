export const SSNMasking = (input: string): string => {
	if (input.length !== 9 || !/^\d{9}$/.test(input)) {
		throw new Error("Input should be a string of 9 digits");
	}

	const firstThree = input.slice(0, 3);
	const secondThree = input.slice(3, 5);
	const lastThree = input.slice(5);

	return `${firstThree}-${secondThree}-${lastThree}`;
};

export const hideSSN = (text: string) => {
	const lastThree = text.slice(5);
	return `XXX-XX-${lastThree}`;
};

export const formatSSN = (SSN: string): string => {
	console.log("========SSN=========", SSN);
	let revisedSSN = SSN;

	if (revisedSSN.length === 8) {
		revisedSSN = "0" + revisedSSN;
	} else if (revisedSSN.length === 7) {
		revisedSSN = "00" + revisedSSN;
	} else if (revisedSSN.length === 6) {
		revisedSSN = "000" + revisedSSN;
	}

	return revisedSSN.substring(0, 3) + "-" + revisedSSN.substring(3, 5) + "-" + revisedSSN.substring(5);
};
