import {
	WorldLogo,
	HelmLogo,
	ShieldLogo,
	EnquiryLogo,
	ReportLogo,
	RiskLogo,
	ErisaLogo,
	LossLogo,
	BenifitsLogo
} from "../assets";

export const SERVICE_CARDS = [
	{
		icons: WorldLogo,
		title: "Confidential",
		description:
			"Maintain current, complete and confidential records and files of claims for each covered person for determination of plan benefits and satisfaction of deductibles."
	},
	{
		icons: HelmLogo,
		title: "Adjudicate",
		description:
			"Evaluate claims as per self-funded plan terms: set fair charges, coordinate benefits with other group plans, employ proper claim controls, and use extra forms for more info."
	},
	{
		icons: ShieldLogo,
		title: "EOBs",
		description:
			"Prepare and distribute benefit checks and explanation of benefits to employees, employers, and/or service providers."
	},
	{
		icons: EnquiryLogo,
		title: "Inquiry Verification",
		description:
			"Address inquiries from employers, staff, hospitals, doctors, and providers about plan details. Benefits are confirmed in writing via fax or electronic signature for client and Nexcaliber safety."
	},
	{
		icons: ReportLogo,
		title: "Summaries / Reports",
		description:
			"Provide periodic claim summaries and reports. Complete and submit all premium reports, statements, claim reports, and other reports required to all insurers and reinsurers of the plan."
	},
	{
		icons: RiskLogo,
		title: "High Risk Claims",
		description: "Conduct audits on claims with elevated risk or significant potential impact."
	},
	{
		icons: ErisaLogo,
		title: "ERISA Compliance",
		description:
			"Supply the requisite information needed to complete the reporting process for ERISA 5500 forms. These forms serve as crucial documents that ensure compliance with regulations, transparency in employee benefit plans, and provide an overview of plan operations to relevant regulatory bodies."
	},
	{
		icons: LossLogo,
		title: "Stop Loss / REIN",
		description:
			"Provide assistance in obtaining competitive Excess Risk Insurance (Individual and Aggregate Stop Loss Coverage) quotes at renewal."
	},
	{
		icons: BenifitsLogo,
		title: "Benefit Communication / Adjustment",
		description:
			"Support in assessing and enacting benefit changes. Offer claimants a toll-free line for coverage queries, claim updates, and suggestions. Representatives access both online and hard copy data. Providers can also discuss service coverage in advance."
	}
];
