import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NexcaliberLogo, NexcaliberPurpleLogo, NexcaliberWhiteLogo } from "../../../assets";
import { IconButton } from "@mui/material";
import { BiMenu } from "react-icons/bi";
import { LazyCustomSidebar } from "..";

import "./navbar.css";

const Navbar = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const location = useLocation();
	const route = location.pathname;

	const handleSidebarToggle = () => {
		setSidebarOpen(!sidebarOpen);
	};

	const handleSidebarClose = () => {
		setSidebarOpen(false);
	};

	return (
		<>
			<LazyCustomSidebar open={sidebarOpen} onClose={handleSidebarClose} />
			<div className="desktop-navbar-wrapper">
				<nav className="navbar">
					<Link to="/" className="navbar-logo-container">
						<img src={NexcaliberWhiteLogo} className="navbar-logo" alt="" />
					</Link>
					<ul className="navbar-list">
						<li className={`navbar-item ${route === "/" ? "active" : ""}`}>
							<a href={route !== "/" ? "/" : "#section1"}>Solutions</a>
						</li>

						<li
							className="navbar-item"
							style={{
								background: "linear-gradient(to right, #9D44C0, #9300FF)",
								marginTop: "-30px",
								marginBottom: "-28px",
								// marginLeft: "-10px",
								paddingTop: 28,
								transform: "skew(150deg)",
								paddingLeft: 24,
								paddingRight: 10
							}}
						>
							<a
								href={route !== "/" ? "/" : "#section2"}
								style={{ transform: "skew(30deg)", color: "white", fontWeight: 700 }}
								className="skew27"
							>
								Nexcaliber Advantage
							</a>
						</li>
						<li className={`navbar-item ${route === "/eligibility-check" ? "active" : ""}`}>
							<Link to="/eligibility-check">
								<a href="#">Eligibility Check</a>
							</Link>
						</li>
						<li className={`navbar-item ${route === "/claim-status" ? "active" : ""}`}>
							<Link to="/claim-status">
								<a href="#">Claim Status</a>
							</Link>
						</li>
						<li className={`navbar-item ${route === "/provider-search" ? "active" : ""}`}>
							<Link to="/provider-search">
								<a href="#">Search Provider</a>
							</Link>
						</li>
						<li className={`navbar-item ${route === "/contact" ? "active" : ""}`}>
							<Link to="/contact">
								<a href="#">Contact Us</a>
							</Link>
						</li>
						<li>
							<a
								href="https://admin.enrollment.nexcaliber.com/login"
								target="_blank"
								className="btn-dark"
								rel="noreferrer"
							>
								Member Login
							</a>
						</li>
					</ul>
				</nav>
			</div>
			<div className="mobile-navbar-wrapper">
				<nav className="mobile-navbar">
					<div className="hamburger-menu-container">
						<IconButton onClick={handleSidebarToggle} className="hamburger-menu-icon" aria-label="delete">
							<BiMenu />
						</IconButton>
					</div>
					<div className="navbar-logo-container">
						<Link to="/">
							<img src={NexcaliberWhiteLogo} className="navbar-logo" alt="" />
						</Link>
					</div>
				</nav>
			</div>
		</>
	);
};

export default Navbar;
