import React from "react";
import { IRemarks } from "../../../../../@types/components/remarks.types";
import RemarksCard from "./remarksCard/RemarksCard";
import { Paper } from "@mui/material";
import "./Remarks.css";

const Remarks = ({ remarks_list }: { remarks_list: IRemarks[] }) => {
	console.log("====explanationCodesInstance====", remarks_list);
	return (
		<Paper elevation={2} style={{ marginTop: 2, padding: 20 }}>
			{remarks_list?.map((remark, index) =>
				remark.EXPLANATION_CODE_1 === 0 &&
				remark.EXPLANATION_CODE_2 === 0 &&
				remark.EXPLANATION_CODE_3 === 0 ? null : (
					<RemarksCard key={index} remark={remark} index={index} />
				)
			)}
		</Paper>
	);
};

export default Remarks;
