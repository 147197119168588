export const prod_url = "https";
export const prod_port = "//dev-portal-backend.enrollment.nexcaliber.com";

export const local_url = "http://localhost";
export const local_port = "4000";

export const NEXCAL_WEB_ENV: "PROD" | "LOCAL" = "PROD";

export const version = "v1";

export const headers = {
	Accept: "application/json",
	"Content-Type": "application/json"
};

export const url = String(NEXCAL_WEB_ENV) === "PROD" ? prod_url : String(NEXCAL_WEB_ENV) === "LOCAL" ? local_url : ""; // Has to change for Dev, local and prod

export const port =
	String(NEXCAL_WEB_ENV) === "PROD"
		? prod_port
		: String(NEXCAL_WEB_ENV) === "LOCAL"
		? local_port
		: "http://localhost:4000"; // Has to change for Dev, local and prod
