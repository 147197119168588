import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ProvidersLocationMap from "../providersLocationMap/ProvidersLocationMap";
import { ProviderMapViewerModalProps } from "../../../../@types/components/providerSearch.type";

import "./providerMapViewerModal.css";

const modalStyle = {
	modalContainer: {
		position: "absolute" as const,
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "80%",
		height: "60%",
		bgcolor: "background.paper",
		boxShadow: 24,
		borderRadius: "1rem",
		p: 4
	},
	modalHeaderContainer: {
		marginTop: "-15px",
		textAlign: "end",
		marginRight: "-20px"
	}
};

const ProviderMapViewerModal: React.FC<ProviderMapViewerModalProps> = ({
	handleOpenProviderMapViewer,
	openMapViewer,
	apiResponse,
	userZipCode
}) => {
	return (
		<div>
			<Modal
				open={openMapViewer}
				onClose={handleOpenProviderMapViewer}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={modalStyle.modalContainer}>
					<Box sx={modalStyle.modalHeaderContainer}>
						<a className="close-button" onClick={handleOpenProviderMapViewer}></a>
					</Box>
					<Box>
						{apiResponse && <ProvidersLocationMap apiResponse={apiResponse} userZipCode={userZipCode} />}
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default ProviderMapViewerModal;
