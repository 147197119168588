import React from "react";

import "./claimStatusMemberDetails.css";
import { SSNMasking, hideSSN } from "../../../../utils/commonFunctions/SSNMasking";
import initCapitalize from "../../../../utils/commonFunctions/initCapitalize";
import { IClaimMemberDetailsProps } from "../../../../@types/components/claimMemberDetails.types";
import { mongoDBDateParserToUSAFormat_v2 } from "../../../../utils/commonFunctions/dates";
import { relationshipMapper } from "../../../../utils/commonFunctions/relationshipMapper";

const ClaimStatusMemberDetails: React.FC<IClaimMemberDetailsProps> = ({ memberDetails }): JSX.Element => {
	return (
		<div className="claim-status-member-details">
			<span className="theme-form-title">Member Details</span>
			<div className="grid-container">
				<div className="grid-items">
					<div className="field-title">Patient Name</div>
					<div className="field-value">
						{initCapitalize(memberDetails.PATIENT_FIRSTNAME) +
							" " +
							initCapitalize(memberDetails.PATIENT_LASTNAME)}
					</div>
				</div>
				<div className="grid-items">
					<div className="field-title">Patient ID</div>
					<div className="field-value">{hideSSN(String(memberDetails.SSN))}</div>
				</div>
				<div className="grid-items">
					<div className="field-title">Patient Date of Birth</div>
					<div className="field-value">{mongoDBDateParserToUSAFormat_v2(memberDetails.DOB)}</div>
				</div>
				<div className="grid-items">
					<div className="field-title">Employer</div>
					<div className="field-value">{memberDetails.GROUP_NAME}</div>
				</div>
				<div className="grid-items">
					<div className="field-title">Employee Name</div>
					<div className="field-value">
						{initCapitalize(memberDetails.INSURED_FIRSTNAME) +
							" " +
							initCapitalize(memberDetails.INSURED_LASTNAME)}
					</div>
				</div>
				<div className="grid-items">
					<div className="field-title">Relationship with Employee</div>
					<div className="field-value">
						{relationshipMapper(memberDetails.SPOUSE, memberDetails.CHILD, memberDetails.OTHER)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClaimStatusMemberDetails;
