import { StatusCodes } from "http-status-codes";
import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";

const { post } = request;

export const contactQueryForm = async (formData: object) => {
	try {
		const endpoint = "nexcaliber-web/contact-form-query";
		// const payload = {
		// 	formData: formData
		// };
		const response = await post(endpoint, formData, {
			...headers
		});

		if (response) {
			// const {
			// 	data: { result }
			// } = response;
			return response;
		} else {
			throw new Error();
		}
	} catch (error: any) {
		if (error.response.status === StatusCodes.BAD_REQUEST) {
			const { message } = error.response.data;

			alert(message);
		} else if (error.response.status === StatusCodes.UNAUTHORIZED) {
			const { message } = error.response.data;

			alert(message);
		} else {
			throw error;
		}
	}
};
