import React from "react";
import { IRemarks } from "../../../../../@types/components/remarks.types";
import RemarksCardv2 from "./remarksCardv2/RemarksCardv2";
import "./Remarksv2.css"; // Assuming you're handling more complex styling here

const Remarksv2 = ({ remarks_list, remark_id }: { remarks_list: IRemarks[]; remark_id: string }) => {
	console.log("====explanationCodesInstance====", remarks_list);

	return (
		<>
			<div style={{ padding: "10px", border: "1px solid black" }}>
				<div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
					<p style={{ fontWeight: "bold", margin: "0" }}>REMARKS</p>
					<p style={{ marginLeft: "10px", fontWeight: "bold", color: "gray" }}>{remark_id}</p>
				</div>
				{remarks_list?.map((remark, index) =>
					remark.EXPLANATION_CODE_1 === 0 &&
					remark.EXPLANATION_CODE_2 === 0 &&
					remark.EXPLANATION_CODE_3 === 0 ? null : (
						<RemarksCardv2 key={index} remark={remark} index={index} remark_id={remark_id} />
					)
				)}
			</div>
		</>
	);
};

export default Remarksv2;
